import React from "react"

class SVG06 extends React.Component  {
  render() {
    return (
      <div className={'mb-3 mb-lg-0 mx-auto'}
        style={{
        maxWidth: '150px',
      }}>
      <svg viewBox="0 0 156 67">
        <path
          fill="none"
          stroke="#29554C"
          strokeWidth={3}
          d="M38.238 65.914c23.055 0 37.062-12.093 37.062-32.364 0-20.271-14.007-32.364-37.062-32.364-22.968 0-36.975 12.093-36.975 32.364 0 20.271 14.007 32.364 36.975 32.364zm.087-12.006c-14.181 0-22.881-6.96-22.881-20.358s8.7-20.358 22.881-20.358c14.094 0 22.794 6.96 22.794 20.358s-8.7 20.358-22.794 20.358zm83.864 12.006c20.619 0 32.625-9.048 32.625-21.576 0-14.442-15.225-20.184-28.536-20.184-10.18 0-20.532 3.306-26.796 9.135.087-15.921 10.179-21.315 24.012-21.315 8.09 0 15.573 2.436 21.14 7.743l8.266-8.091c-6.7-6.525-16.965-10.44-30.711-10.44-22.881 0-36.801 12.528-36.801 32.364s13.92 32.364 36.8 32.364zm0-10.788c-10.614 0-18.705-3.48-21.576-12.963 5.916-5.22 14.877-7.917 22.707-7.917 9.918 0 17.487 2.436 17.487 10.005 0 7.395-7.482 10.875-18.618 10.875z"
        />
      </svg>
      </div>
    )
  }
}

export default SVG06
