import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'
import SVGLocationAndHours from './svg/location-and-hours'
import SVG04 from './svg/04'
import SVGLocationRounded from './svg/location-rounded'
import SVGHours from './svg/hours'
import SVGArrow from './svg/arrow'
import GoogleMap from './google-map'

const SectionLocation = ({ address, hours, gmaps_url }) => (
  <div className={'section section-location section-content'}>
    <Fade>
      <div className={'container-fluid px-3 px-md-0'}>
        <div className={'row pb-5 mx-lg-5'}>
          <Parallax className={'col-md-12'} x={[0, 4]}>
            <div className={'svg-04-wrapper d-block d-md-none mb-4 mx-auto'} style={{ maxWidth: '155px' }}>
              <SVG04 />
            </div>
            <div className={'mb-5 mb-md-0'} style={{ maxWidth: '610px' }}>
              <SVGLocationAndHours />
            </div>
          </Parallax>
        </div>

        <div className={'row'}>
          <div className={'col-md-6 order-2 order-md-1'}>
            <GoogleMap />
          </div>

          <Parallax
            className={'col-md-5 offset-md-1 order-1 order-md-2'}
            y={[-10, 20]}
            styleInner={{ transition: 'transform 0s linear 0s' }}>
            <div className={'svg-04-wrapper d-none d-md-block pb-5'} style={{ maxWidth: '155px' }}>
              <SVG04 />
            </div>
            <div className={'location-details pb-5 mx-5 mx-md-0'}>
              <SVGLocationRounded />
              <div className={'entry-content'}>
                <div dangerouslySetInnerHTML={{ __html: address }} />
                <a
                  href={gmaps_url}
                  title="Open Google Maps in new window."
                  className={'gmaps-link'}
                  target="_blank"
                  rel="noopener noreferrer">
                  Open in Google Maps <SVGArrow />
                </a>
              </div>
            </div>
            <div className={'location-details pb-5 mx-5 mx-md-0'}>
              <SVGHours />
              <div className={'pb-0 pb-md-5 entry-content'} dangerouslySetInnerHTML={{ __html: hours }} />
            </div>
          </Parallax>
        </div>
      </div>
    </Fade>
  </div>
)

SectionLocation.propTypes = {
  address: PropTypes.string,
  hours: PropTypes.string,
}

SectionLocation.defaultProps = {
  address: ``,
  hours: ``,
}

export default SectionLocation
