import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

const PopupModal = ({ image, url, html }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if ((html || image) && !getCookie('modalshown')) {
      console.log('showing modal')
      setCookie('modalshown')
      setShow(true)
    }
  }, [image, html])

  function getCookie(cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  function setCookie(cname) {
    var now = new Date()
    now.setTime(now.getTime() + 24 * 60 * 60 * 1000) // 1 day from now
    document.cookie = cname + '=true; expires=' + now.toUTCString() + ';'
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      backdropClassName={'backdropGreen'}
      dialogClassName={'dialogGreen popupModal'}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {html ? 
        <div dangerouslySetInnerHTML={{__html: html}} /> :
        <a href={url}>
          <img
            alt={'Alert'}
            src={image ? image.url : ''}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </a>
        }
        
      </Modal.Body>
    </Modal>
  )
}

export default PopupModal
