import React from "react"

class SVGLogo extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287 30">
        <title className={'sr-only'} lang="en">Gattara Logo</title>
        <path fill="#FAECE7" d="M23.7 14.6l7.92-.32c-.12 1.52-.12 2.76-.12 4.24l.04 5.24c-1.6 2-6.28 5.84-14.6 5.84C7.34 29.6.98 23.44.98 15.2.98 5.68 8.74.44 17.3.44c4.2 0 7.28 1.04 9.88 2.56l-1.84 3.56c-1.64-.92-3.76-1.6-6.08-1.6-5.84 0-9.24 4.44-9.24 9.76 0 5.4 3.48 10.24 9.4 10.24 1.44 0 2.92-.24 4.32-.68.08-1.52.12-3.6.12-5.48 0-1.72-.08-3.28-.16-4.2zm44.04-9.4l8.08 18.64c.88 2.04 1.4 3.24 2.64 5.16H68.3c-.44-1.76-.88-2.92-1.68-4.72l-.32-.68H52.42c-.72 1.68-1.56 3.76-1.96 5.4h-6.44c.96-1.72 2.12-4.16 2.88-5.92l7.64-17.6c.56-1.28 1.28-3.08 1.64-4.32l9.8-.32c.48 1.28 1.24 3.16 1.76 4.36zm-3.16 14.52L59.34 7.96l-5.2 11.76h10.44zM104.7 4.84v17.28c0 2.28 0 4.6.16 6.88h-9.08s.24-4.56.24-6.72V4.84h-2.48c-2.6 0-4.84.32-6.68 1.16l.76-5.24c1.8.16 3.56.24 5.36.24h14.6c1.8 0 3.56-.08 5.36-.24L113.7 6c-1.84-.84-4.08-1.16-6.68-1.16h-2.32zm40.44 0v17.28c0 2.28 0 4.6.16 6.88h-9.08s.24-4.56.24-6.72V4.84h-2.48c-2.6 0-4.84.32-6.68 1.16l.76-5.24c1.8.16 3.56.24 5.36.24h14.6c1.8 0 3.56-.08 5.36-.24l.76 5.24c-1.84-.84-4.08-1.16-6.68-1.16h-2.32zm41.12.36l8.08 18.64c.88 2.04 1.4 3.24 2.64 5.16h-10.16c-.44-1.76-.88-2.92-1.68-4.72l-.32-.68h-13.88c-.72 1.68-1.56 3.76-1.96 5.4h-6.44c.96-1.72 2.12-4.16 2.88-5.92l7.64-17.6c.56-1.28 1.28-3.08 1.64-4.32l9.8-.32c.48 1.28 1.24 3.16 1.76 4.36zm-3.16 14.52l-5.24-11.76-5.2 11.76h10.44zm53.44 2.96c2.44 2.32 3.44 4.52 3.92 6.32h-9.2c-.96-2.16-1.76-3.72-3.24-5.4-1.68-1.88-2.92-2.92-4.52-4.04h-4v4.6c0 2.04.24 4.84.24 4.84h-9.16s.24-2.8.24-4.84V4.64c0-1.76-.16-3.64-.16-3.64h13.2c7.6 0 12 3.56 12 9.28 0 3.6-1.44 6.36-4.88 7.88 2.08 1.4 3.6 2.64 5.56 4.52zm-17.04-6.8h.8c5.32 0 7.36-2.84 7.36-5.68 0-2.76-2.08-5.76-6.48-5.76h-1.68v11.44zM275.7 5.2l8.08 18.64c.88 2.04 1.4 3.24 2.64 5.16h-10.16c-.44-1.76-.88-2.92-1.68-4.72l-.32-.68h-13.88c-.72 1.68-1.56 3.76-1.96 5.4h-6.44c.96-1.72 2.12-4.16 2.88-5.92l7.64-17.6c.56-1.28 1.28-3.08 1.64-4.32l9.8-.32c.48 1.28 1.24 3.16 1.76 4.36zm-3.16 14.52L267.3 7.96l-5.2 11.76h10.44z"/>
      </svg>
    )
  }
}

export default SVGLogo
