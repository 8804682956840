import React from 'react'
import PropTypes from 'prop-types'
import ScrollMagic from '../lib/scrollmagic'
import debugAddIndicators from '../lib/debug.addIndicators.js'

debugAddIndicators(ScrollMagic)

const refOrInnerRef = child => {
  if (child.type && child.type.$$typeof && child.type.$$typeof.toString() === 'Symbol(react.forward_ref)') {
    return 'ref'
  }

  // styled-components < 4
  if (child.type && child.type.styledComponentId) {
    return 'innerRef'
  }

  return 'ref'
}

const callChildFunction = (children, progress, event) => {
  if (children && typeof children === 'function') {
    return children(progress, event)
  }
  return children
}

const getChild = (children, progress, event) => {
  children = callChildFunction(children, progress, event)
  return React.Children.only(children)
}

const isString = element => {
  if (typeof element === 'string' || element instanceof String) {
    return true
  }
  return false
}

class Scene extends React.Component {
  constructor(props) {
    super(props)
    this.scene = null
    this.state = {
      event: 'init',
      progress: 0,
    }
  }

  componentDidMount() {
    const { children, controller, classToggle, pin, pinSettings, indicators, enabled, ...sceneParams } = this.props

    const element = this.ref
    sceneParams.triggerElement = sceneParams.triggerElement === null ? null : sceneParams.triggerElement || element

    this.scene = new ScrollMagic.Scene(sceneParams)

    this.initEventHandlers()

    if (classToggle) {
      this.setClassToggle(this.scene, element, classToggle)
    }

    if (pin || pinSettings) {
      this.setPin(this.scene, element, pin, pinSettings)
    }

    if (indicators) {
      this.scene.addIndicators()
    }

    this.scene.addTo(controller)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.duration !== prevProps.duration) {
      this.updateDuration(this.props.duration)
    }
  }

  componentWillUnmount() {
    this.scene.destroy()
  }

  updateDuration(duration) {
    //console.log('updating duration')
    this.scene.duration(duration)
  }

  initEventHandlers() {
    let { children } = this.props

    if (typeof children !== 'function') {
      return
    }

    this.scene.on('start end enter leave', event => {
      this.setState({
        event,
      })
    })

    this.scene.on('progress', event => {
      this.setState({
        progress: event.progress,
      })
    })
  }

  setClassToggle(scene, element, classToggle) {
    if (Array.isArray(classToggle) && classToggle.length === 2) {
      scene.setClassToggle(classToggle[0], classToggle[1])
    } else {
      scene.setClassToggle(element, classToggle)
    }
  }

  setPin(scene, element, pin, pinSettings) {
    element = isString(pin) ? pin : element
    scene.setPin(element, pinSettings)
  }

  render() {
    let { children } = this.props
    const { progress, event } = this.state

    const child = getChild(children, progress, event)

    // TODO: Don't add ref to stateless or stateful components

    return React.cloneElement(child, { [refOrInnerRef(child)]: ref => (this.ref = ref) })
  }
}

Scene.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

  // scene parameters
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  triggerElement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  triggerHook: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reverse: PropTypes.bool,
  indicators: PropTypes.bool,
  enabled: PropTypes.bool,

  classToggle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  pin: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

Scene.defaultProps = {
  duration: 0,
  offset: 0,
}

export default Scene
