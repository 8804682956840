/**
 * Use this class to ensure Google Maps API javascript is loaded before running any google map specific code.
 */
export default class GoogleMapsApi {
  /**
   * Constructor set up config.
   */
  constructor() {
    // api key for google maps
    this.apiKey = 'AIzaSyCRuz93Qaq5mDJ3N3Zi_jv9miiNmWCJxww'
    this.callbackName = '_GoogleMapsApi.mapLoaded'

    // set a globally scoped callback if it doesn't already exist
    if (!window._GoogleMapsApi) {
      window._GoogleMapsApi = this
      window._GoogleMapsApi.mapLoaded = this.mapLoaded.bind(this)
    }
  }

  /**
   * Load the Google Maps API javascript
   */
  load() {
    if (!this.promise) {
      this.promise = new Promise(resolve => {
        this.resolve = resolve
        if (typeof window.google === 'undefined') {
          const script = document.createElement('script')
          script.src = `//maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=${this.callbackName}`
          script.async = true
          document.body.appendChild(script)
          //console.log('loading google maps script')
        } else {
          this.resolve()
        }
      })
    }

    return this.promise
  }

  /**
   * Globally scoped callback for the map loaded
   */
  mapLoaded() {
    //console.log('maps loaded')
    if (this.resolve) {
      this.resolve()
    }
  }
}
