import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'

import Svg06 from './svg/06'
import ImagePizzaCat from './images/pizza-cat'
import SVGCateringEvents from './svg/catering-events'
import SVGCateringEventsMobile from './svg/catering-events-mobile'

class SectionCatering extends React.Component {
  render() {
    const {catering} = this.props;
    return (
      <Fade big delay={800}>
        <div
          className={'section-content section-catering'}
          style={{
            position: 'relative',
          }}>
          <Parallax x={[0, 4]}>
            <div className={'wrapper-top text-center text-lg-left d-flex align-items-center mg-lb-5'}>
              <div className={'d-block d-lg-inline-block ml-lg-5 mr-5 mb-4 mb-lg-0'}>
                <Svg06 />
              </div>
              <div className={'d-inline-block mr-3'}>
                <SVGCateringEvents className={'d-none d-lg-block' }/>
                <SVGCateringEventsMobile className={'d-lg-none'} />
              </div>
            </div>
          </Parallax>

          <div className={'container'}>
            <div className={'row pb-lg-5'}>
              <Parallax className={'col-lg-5 text-center my-5 order-2 order-lg-1'} y={[40, -10]}>
                <div className="px-lg-5 pt-lg-5">
                  <ImagePizzaCat />
                </div>
              </Parallax>
              <div className={'col-lg-6 mt-5 order-1 order-lg-2'}>
                <div className={'menu-block deep-pink mx-md-5 mx-lg-0 px-5 py-5'}>
                  <h5 className={'title h5 text-deeppink'}>OffSite Events</h5>
                  <div className={'mb-5'} dangerouslySetInnerHTML={{__html: catering.offsite_events}} />
                  <h5 className={'title h5 text-deeppink'}>Onsite Events</h5>
                  <div className={'mb-5'} dangerouslySetInnerHTML={{__html: catering.onsite_events}} />
                  <h5 className={'title h5 text-deeppink'}>Email</h5>
                  <div dangerouslySetInnerHTML={{__html: catering.email}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}

SectionCatering.propTypes = {
  catering: PropTypes.object,
}

SectionCatering.defaultProps = {
  catering: {
    offsite_events: '',
    onsite_events: '',
    email: '',
  }
}

export default SectionCatering
