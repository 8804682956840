import React from "react"

const SVGFeaturedFilm = ({className}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="303" height="298" viewBox="0 0 303 298" className={className}>
    <title className={'sr-only'} lang="en">Featured Film</title>
    <g fill="none" fillRule="evenodd" stroke="#E6ADAD">
      <path d="M8.652 50.4l3.571 4.191 11.955-10.186 3.042 3.57-11.954 10.188 5.777 6.778-4.139 3.528L1.47 50.36l18.32-15.612 3.04 3.57zM67.79 30.282l1.976 4.107-22.093 10.63L37.35 23.57l21.787-10.483 1.976 4.105-16.915 8.14 2.123 4.413 14.127-6.798 1.975 4.107L48.3 33.85l2.27 4.72zm31.82-12.94l-6.602-8.64-3.096 10.421 9.697-1.782zm3.04 3.934l-14.146 2.602-1.6 5.41-5.718 1.051 8.105-25.69 5.852-1.076 16.71 21.13-5.786 1.062-3.416-4.489zm44.154-14.423l-10.386-.565-1.037 19.077-5.431-.297 1.037-19.075-10.318-.56.254-4.686 26.135 1.422z"/>
      <path d="M192.3 14.179l-3.924 11.955c-2.227 6.783-8.224 9.898-16.43 7.203-8.207-2.692-11.191-8.753-8.964-15.538l3.922-11.955 5.17 1.697-3.797 11.566c-1.484 4.524.275 8.107 5.154 9.708 4.879 1.6 8.419-.244 9.903-4.766l3.797-11.566 5.168 1.696zm27.616 28.514c2.365 1.602 4.491 2.219 5.979.023 1.486-2.197.122-3.942-2.244-5.543l-8.899-6.023-3.734 5.52 8.898 6.023zm-3.14 13.068l-1.123-10.532-7.123-4.823-4.536 6.702-4.478-3.03 13.341-19.712 13.602 9.205c4.816 3.26 6.941 7.943 4.045 12.224-2.27 3.35-5.904 3.927-9.685 2.478l1.337 11.128-5.38-3.64zm35.656 29.479l-3.451 2.973-16-18.568 18.029-15.533 15.777 18.31-3.451 2.974-12.25-14.217-3.707 3.196 10.23 11.873-3.451 2.973-10.23-11.873-3.967 3.418zm24.902 30.383c4.426-2.09 4.923-6.61 2.484-11.775l-2.904-6.149-13.156 6.214 2.903 6.15c2.44 5.163 6.247 7.651 10.673 5.56zm2.366 5.011c-6.702 3.165-13.213-.113-17.045-8.229l-5.402-11.435 21.518-10.163 5.402 11.435c3.832 8.115 2.229 15.227-4.473 18.392zm16.496 45.688l-5.503.2.573 15.697-4.687.17-.573-15.695-8.9.325-.199-5.435 23.78-.869.878 24.052-4.689.172zm-20.459 34.531l23.469 3.938-.9 5.364-23.469-3.937zm-8.378 45.071l-4.385-1.668 7.922-20.813 22.243 8.465-1.935 5.085-17.858-6.797zm-10.87 50.79l-19.05-14.267 3.26-4.353 13.254 9.925-3.949-15.998.163-.219 16.463-.712-13.254-9.925 3.158-4.218 19.051 14.266-3.545 4.734-16.103.517 4.037 15.596z"/>
    </g>
  </svg>
)

export default SVGFeaturedFilm