import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'

import SvgMenus from './svg/menus'
import Svg02 from './svg/02'
import SvgEatDrink from './svg/eat-drink'
import ImageMenuCat from '../components/images/menu-cat'
// import PdfModal from '../components/pdf-modal'

class SectionMenu extends React.Component {
  render() {
    return (
      <Fade big delay={800}>
        <div
          className={'section-content section-menus'}
          style={{
            position: 'relative',
          }}>
          <Parallax x={[0, -5]}>
            <div className={'svg-menus-wrapper wrapper-top text-center text-md-left'}>
              <div className={'d-block d-lg-inline-block mr-lg-5 mb-4 mb-md-4'}>
                <Svg02 />
              </div>
              <div className={'d-inline-block mr-3'}>
                <SvgMenus />
              </div>
            </div>
          </Parallax>

          <div className={'container'}>
            <div className={'row pb-lg-5'}>
              <Parallax className={'col-lg-5 text-center my-5'} y={[40, -10]}>
                <ImageMenuCat />
              </Parallax>
              <div className={'col-lg-6 mx-lg-5'}>
                <Parallax y={[-10, 20]}>
                  <div className={'menu-block deep-pink mx-md-5 mx-lg-0 px-5 py-5'}>
                    <ol className={'food-menu-list'}>
                      {this.props.menus.map(menu => (
                        <li key={menu.menu_name}>
                          {/* <PdfModal menuName={menu.menu_name} menuLink={menu.menu_link} menuImage={menu.menu_image} /> */}
                          <a
                            className="menu-link link-underline"
                            href={menu.menu_link}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {menu.menu_name}
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                </Parallax>
                <div
                  className={'mx-auto mt-5 mt-md-0 ml-md-auto mr-md-5 mb-5'}
                  style={{ maxWidth: '175px', height: '175px' }}>
                  <SvgEatDrink rotation={this.props.rotation} />
                </div>
              </div>
            </div>
          </div>

          <div className={'svg-menus-wrapper wrapper-bottom'}>
            <Parallax x={[-8, 1]}>
              <div className={'d-none d-lg-inline-block mr-lg-5'}>
                <Svg02 />
              </div>
              <div className={'d-none d-lg-inline-block'}>
                <SvgMenus />
              </div>
            </Parallax>
          </div>
        </div>
      </Fade>
    )
  }
}

SectionMenu.propTypes = {
  menus: PropTypes.array,
}

SectionMenu.defaultProps = {
  menus: [],
}

export default SectionMenu
