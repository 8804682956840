import React from 'react'

import Menu from './menu'
import OrderOnlineDropdown from './order-online-dropdown'

const NavMobile = ({ callback, reserveLink, orderOnline }) => (
  <div className={'site-menu-mobile text-center'}>
    <div className={'text-center mt-5 pt-5 mx-5'}>
      {reserveLink && (
        <a
          href={reserveLink}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-block btn-outline btn-primary mb-3">
          Reserve
        </a>
      )}
      {orderOnline && (
        <OrderOnlineDropdown className={'mb-4'} links={orderOnline} buttonClass="btn btn-block btn-outline btn-primary" />
      )}
    </div>

    <Menu callback={callback} reserveLink={reserveLink} />
  </div>
)

NavMobile.defaultProps = {
  reserveLink: '#',
  orderOnline: [],
}

export default NavMobile
