import React from "react"

class SvgAboutVertical extends React.Component  {

  render() {
    return (
      <>
      <div className={'d-none d-md-block section-title mx-auto'} style={{ maxWidth: '175px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="145" height="161" viewBox="0 0 145 161">
          <title className={'sr-only'} lang="en">About</title>
          <path fill="none" stroke="#E6ACAE" d="M21.665 5.175l7.07 16.31c.77 1.785 1.225 2.835 2.31 4.515h-8.89c-.385-1.54-.77-2.555-1.47-4.13l-.28-.595H8.26c-.63 1.47-1.365 3.29-1.715 4.725H.91c.84-1.505 1.855-3.64 2.52-5.18l6.685-15.4c.49-1.12 1.12-2.695 1.435-3.78l8.575-.28a96.186 96.186 0 0 0 1.54 3.815zM18.9 17.88L14.315 7.59l-4.55 10.29H18.9zm33.675 29.05C55.69 47.98 58 50.08 58 53.475 58 59.215 52.96 61 49.355 61H34.83s.21-4.025.21-5.81V40.7c0-1.54-.14-4.2-.14-4.2h12.74c2.45 0 7.315 1.26 7.315 6.055 0 1.925-.98 3.36-2.38 4.375zm-9.94-7.385v6.615h2.38c2.485 0 3.885-1.505 3.885-3.5 0-1.855-1.61-3.115-4.305-3.115h-1.96zm0 18.41h2.59c3.71 0 5.6-2.03 5.6-4.55 0-2.415-1.54-4.27-5.495-4.27h-2.695v8.82zM70.978 70.08c8.05 0 13.51 5.18 13.51 12.67 0 7.875-5.67 12.705-13.51 12.705-8.085 0-13.51-4.9-13.51-12.705 0-7.42 5.564-12.67 13.51-12.67zm-.56 3.78c-3.536.14-5.32 3.57-4.97 9.275.35 5.81 2.555 8.645 6.124 8.505 3.605-.14 5.32-3.535 5.005-9.24-.314-5.705-2.555-8.68-6.16-8.54zm35.752 30.71l5.04-.14c.035 0-.105 1.855-.105 3.395v9.1c0 7.945-4.935 12.53-11.795 12.53-8.015 0-12.425-4.76-12.425-12.04v-9.59c0-1.54-.14-3.255-.14-3.255l7.84-.14c.035 0-.105 1.855-.105 3.395v8.82c0 5.04 2.695 7.28 5.915 7.28s5.915-2.275 5.915-7.385v-8.715c0-1.54-.14-3.255-.14-3.255zm29.675 34.29v15.12c0 1.995 0 4.025.14 6.02h-7.945s.21-3.99.21-5.88v-15.26h-2.17c-2.275 0-4.235.28-5.845 1.015l.665-4.585c1.575.14 3.115.21 4.69.21h12.775c1.575 0 3.115-.07 4.69-.21l.665 4.585c-1.61-.735-3.57-1.015-5.845-1.015h-2.03z"/>
        </svg>
      </div>

      <div className={'d-block d-md-none mb-5 section-title mx-auto'} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407 67">
          <title className={'sr-only'} lang="en">About</title>
          <path fill="none" stroke="#EEC3BE" strokeWidth="2" d="M65.902 64l-6.525-12.876H22.576L16.051 64H1.174L33.451 3.1h15.225L80.953 64H65.902zM28.318 39.814h25.23L40.933 15.019 28.318 39.814zm114.575-7.656c9.57.522 17.052 5.481 17.052 15.138 0 10.788-9.222 16.704-23.838 16.704H90.78V3.1h41.847c14.616 0 23.838 5.742 23.838 15.573 0 6.96-4.524 12.876-13.572 13.485zm-38.367-18.096v13.746h25.839c7.569 0 12.267-1.218 12.267-6.873 0-5.568-4.698-6.873-12.267-6.873h-25.84zm28.71 38.976c7.83 0 12.44-1.218 12.44-7.308 0-6.177-4.61-7.308-12.44-7.308h-28.71v14.616h28.71zm75.163 12.876c-23.75 0-38.715-12.006-38.715-32.364 0-20.358 14.964-32.364 38.715-32.364 23.751 0 38.715 12.006 38.715 32.364 0 20.358-14.964 32.364-38.715 32.364zm0-12.006c15.051 0 24.534-7.308 24.534-20.358S223.45 13.192 208.4 13.192c-15.05 0-24.534 7.308-24.534 20.358s9.396 20.358 24.534 20.358zM314.622 3.1h13.92v32.19c0 18.27-12.093 30.624-34.191 30.624-22.098 0-34.191-12.354-34.191-30.624V3.1h13.92v31.146c0 12.18 7.134 19.488 20.27 19.488 13.138 0 20.272-7.308 20.272-19.488V3.1zm90.997 0v12.006h-26.622V64h-13.92V15.106H338.63V3.1h66.99z"/>
        </svg>
      </div>
    </>
    )
  }
}

export default SvgAboutVertical
