import React from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import ImageFadeIn from 'react-image-fade-in'

import ScrollButton from './scroll-button'
import DiscoverMore from './svg/discover-more'

class SectionHero extends React.Component {
  render() {
    var SliderSettings = {
      dots: false,
      infinite: true,
      speed: 3000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      draggable: false,
      swipe: false,
      fade: true,
    }

    return (
      <Fade top ssrReveal>
        <div
          className={'section section-hero'}
          style={{
            minHeight: '100vh',
            height: '100vh',
            position: 'relative',
            zIndex: '100',
          }}>
          <div
            className={'section-hero-inner row align-items-center justify-content-md-center no-gutters'}
            style={{
              minHeight: '100vh',
              height: '100%',
              position: 'relative',
              zIndex: '100',
            }}>
            <div
              className={'text-center px-5'}
              style={{
                maxWidth: '800px',
              }}>
              <h2 className={'h2 title text-softpink text-uppercase'}>{this.props.text}</h2>

              <h2 className={'sr-only'}>{this.props.text}</h2>
            </div>

            <div className={'pt-2 pb-5 py-md-4 d-flex justify-content-center scroll-indicator'}>
              <ScrollButton className={'text-center btn btn-link'} target=".section-about">
                <span className={'mouse mb-4'} />
                <span className={'label d-block mx-auto'}>
                  <i className="sr-only">Discover more</i>
                  <div style={{ maxWidth: '165px' }}>
                    <DiscoverMore />
                  </div>
                </span>
              </ScrollButton>
            </div>
          </div>

          <div
            className={'section-hero-background'}
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              zIndex: '10',
            }}>
            <div className={'hero-background-overlay'} />
            {this.props.images.length > 1 ? (
              // more than one image, so make it a slider
              <Slider {...SliderSettings}>
                {this.props.images.map(image => (
                  <div key={image}>
                    {image.image !== undefined && image.image.endsWith('.mp4') ? (
                      <div
                        className={'video-container'}
                        style={{
                          width: '100%',
                          height: '100vh',
                          overflow: 'hidden',
                        }}>
                        <video autoPlay loop muted playsInline>
                          <source src={image.image} type="video/mp4" />
                        </video>
                      </div>
                    ) : (
                      <ImageFadeIn
                        src={image.image}
                        width={'100%'}
                        height={'100vh'}
                        loadAsBackgroundImage={true}
                        style={{
                          width: '100%',
                          height: '100vh',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              // only one image, don't need slider wrapper
              this.props.images.map(image => (
                <div key={image}>
                  {image.image.endsWith('.mp4') ? (
                    <div
                      className={'video-container'}
                      style={{
                        width: '100%',
                        height: '100vh',
                        overflow: 'hidden',
                      }}>
                      <video autoPlay loop muted playsInline>
                        <source src={image.image} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <ImageFadeIn
                      src={image.image}
                      width={'100%'}
                      height={'100vh'}
                      loadAsBackgroundImage={true}
                      style={{
                        width: '100%',
                        height: '100vh',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </Fade>
    )
  }
}

SectionHero.defaultProps = {
  images: [],
  text: '',
}

export default SectionHero
