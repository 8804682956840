import React from "react"

class SVGClose extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
        <title className={'sr-only'} lang="en">Close Button</title>
        <path fill="#34544C" d="M11.862.785A.423.423 0 0 0 12 .462a.453.453 0 0 0-.138-.333.438.438 0 0 0-.324-.129.47.47 0 0 0-.332.13L6 5.343.785.13A.438.438 0 0 0 .462 0a.47.47 0 0 0-.333.13A.47.47 0 0 0 0 .461c0 .127.043.235.13.323L5.343 6 .13 11.206a.47.47 0 0 0-.129.332c0 .128.043.236.13.324A.449.449 0 0 0 .463 12a.423.423 0 0 0 .323-.138L6 6.656l5.206 5.206a.452.452 0 0 0 .332.138.423.423 0 0 0 .324-.138.423.423 0 0 0 .138-.324.453.453 0 0 0-.138-.332L6.656 6 11.862.785z"/>
      </svg>
    )
  }
}

export default SVGClose
