import React from 'react'
import ScrollButton from './scroll-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import OrderOnlineDropdown from './order-online-dropdown'

const NavbarHero = ({instagram, facebook, orderOnline, reserveLink}) => {
  return (
    <nav className={'navbar navbar-hero navbar-expand-lg navbar-light px-0'}>
      <div className={'collapse navbar-collapse'} id="navbarSupportedContent">
        <ul className={'navbar-nav navbar-nav-primary mr-auto '}>
          <li className={'nav-item d-none d-lg-inline-block mr-5'}>
            <ScrollButton className={'btn btn-link link-underline px-0'} target=".section-about">
              About
            </ScrollButton>
          </li>
          <li className={'nav-item d-none d-lg-inline-block mr-5'}>
            <ScrollButton className={'btn btn-link link-underline px-0'} target=".section-menus">
              Menus
            </ScrollButton>
          </li>
          <li className={'nav-item d-none d-lg-inline-block mr-5'}>
            <ScrollButton className={'btn btn-link link-underline px-0'} target=".section-location">
              Location
            </ScrollButton>
          </li>
          <li className={'nav-item d-none d-lg-inline-block mr-5'}>
            <ScrollButton className={'btn btn-link link-underline px-0'} target=".section-contact">
              Contact
            </ScrollButton>
          </li>
        </ul>

        <ul className={'navbar-nav ml-auto'}>
          <li className={'nav-item mr-3'}>
            <ul className={'social-media-list list-inline my-0'}>
              <li className={'list-inline-item'}>
                <a className={' social-link d-block px-2'} href={instagram}>
                  <FontAwesomeIcon icon={faInstagram} fixedWidth />
                  <span className={'sr-only'}>Instagram</span>
                </a>
              </li>
              <li className={'list-inline-item'}>
                <a className={' social-link d-block px-2'} href={facebook}>
                  <FontAwesomeIcon icon={faFacebookF} fixedWidth />
                  <span className={'sr-only'}>Facebook</span>
                </a>
              </li>
            </ul>
          </li>
          {orderOnline && (
            <OrderOnlineDropdown el={'li'} links={[...orderOnline, {name:'Reserve a Table', url: reserveLink}]} className={'nav-item mr-3'} buttonLabel="Order / Reserve" />
          )}
        </ul>
      </div>
    </nav>
  )
}

NavbarHero.defaultProps = {
  reserveLink: '#',
  orderOnline: [],
  facebook: '#',
  instagram: '#',
}

export default NavbarHero
