import React from "react"

class SVGArrow extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.311 3.197" alt="Arrow Image">
        <title className={'sr-only'} lang="en">Arrow Image</title>
        <path d="M6.074 1.362l-1.14 1.14c-.088.087-.371.33-.488.37l.325.325.04-.065c.032-.055.25-.284.296-.33l1.204-1.204z" fill="#e7adae" fillRule="evenodd"/>
        <path d="M6.074 1.835L4.934.695c-.088-.087-.371-.33-.488-.37L4.77 0l.04.064c.032.056.25.285.296.33l1.204 1.204zM4.793 1.787a.19.19 0 0 1-.188-.189.19.19 0 0 1 .188-.188.188.188 0 0 1 0 .377M.92 1.598a.188.188 0 1 1 .19.189.188.188 0 0 1-.189-.189m.921 0a.188.188 0 1 1 .377 0 .188.188 0 0 1-.377 0m.921 0a.188.188 0 1 1 .377 0 .188.188 0 0 1-.377 0m.921 0a.189.189 0 1 1 .377.001.189.189 0 0 1-.377 0" fill="#e7adae" fillRule="evenodd"/>
      </svg>
    )
  }
}

export default SVGArrow
