import React from "react"

class SvgMenus extends React.Component  {

  render() {
    return (
      <div className={'section-title '}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 67" preserveAspectRatio="xMinYMin meet">
          <title className={'sr-only'} lang="en">Menus</title>
          <path fill="none" stroke="#EEC3BE" d="M67.82 3.1h14.877V64h-13.92V21.631L42.068 54.256h-.696L14.663 21.631V64H1.178V3.1h15.138l25.752 32.19L67.82 3.1zm46.628 49.242h48.894V64h-62.727V3.1h61.857v11.658h-48.024v12.528h40.107v11.658h-40.107v13.398zM232.763 3.1h13.485V64h-12.18l-43.674-44.022V64H176.91V3.1h14.79l41.064 41.76V3.1zm84.82 0h13.92v32.19c0 18.27-12.092 30.624-34.19 30.624S263.122 53.56 263.122 35.29V3.1h13.92v31.146c0 12.18 7.134 19.488 20.27 19.488 13.138 0 20.272-7.308 20.272-19.488V3.1zm62.288 23.403c19.401 1.566 33.06 5.655 33.06 19.053 0 12.006-11.397 20.358-32.973 20.358-15.399 0-27.927-4.35-37.236-12.267l8.004-9.483c7.482 6.873 17.14 10.092 29.58 10.092 12.354 0 18.618-2.349 18.618-7.395 0-5.046-6.264-6.438-20.27-7.656-17.4-1.479-33.583-5.307-33.583-18.357 0-12.702 13.572-19.662 32.364-19.662 13.311 0 24.795 3.306 32.538 9.309l-7.656 9.657c-6.699-5.133-14.964-7.221-24.882-7.308-8.265-.087-18.357 1.392-18.357 6.96 0 5.22 8.7 5.742 20.793 6.699z"/>
        </svg>
      </div>
    )
  }
}

export default SvgMenus
