import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'
import SVGInTheNews from './svg/in-the-news'
import Svg07 from './svg/07'
import SvgPressContact from './svg/press-contact'
import ImageContactCat from './images/contact-cat'
import SVGFeaturedFilm from './svg/featured-film'
import SVGFilmMobile from './svg/featured-film-mobile'

class SectionPress extends React.Component {
  render() {
    const {video_embed_code, video_text} = this.props
    return (
      <Fade big delay={800}>
        <div
          className={'section-content section-press'}
          style={{
            position: 'relative',
          }}>
          <div className={'container'}>
            <div className={`row mb-4 mb-md-5 mr-md-5 align-items-md-end `}>
              <Parallax className={'col-md-8 order-2 order-md-1 text-center text-md-left'} x={[0, 25]}>
                <SVGInTheNews />
              </Parallax>
              <div className={`col-md-4 order-1 order-md-2 text-center text-md-right`}>
                <Svg07 />
              </div>
            </div>

            <ul className={'list-unstyled press-list row no-gutters mb-5 mr-md-5'}>
              {this.props.press.map(pressItem => (
                <li className={'col-md-6 '} key={pressItem.title}>
                  <a
                    target="_blank"
                    className={'d-block py-4 px-3 text-uppercase'}
                    href={pressItem.url}
                    rel="noopener noreferrer">
                    <h6 className={`press-title`}>{pressItem.title}</h6>
                    <small className={'d-inline-block link-underline'}>Read The Article</small>
                  </a>
                </li>
              ))}
            </ul>

            <div className={'row no-gutters mb-5 mr-md-5'}>
              <div className={'col-12 col-md-4'}>
                <div className={'contact-press-item'}>
                  <SvgPressContact />
                  <span dangerouslySetInnerHTML={{ __html: this.props.pressContact }} />
                </div>
              </div>
              <div className={'col-12 col-md-4'} style={{ maxWidth: '225px' }}>
                <ImageContactCat />
              </div>
            </div>
          </div>
          {video_embed_code && 
          <div className={`section-video row pt-5 mb-4 mb-md-5 mr-md-5 justify-content-center`}>
          <div className={`col-10 col-md-8 pt-md-5`}>
            <div className="video-container">
              <div className={`desktop-svg d-none d-md-block`}>
                <SVGFeaturedFilm />
              </div>
              <div className={`mobile-svg d-md-none`} >
                <SVGFilmMobile  />
              </div>
              <div className="video-wrapper mb-4" dangerouslySetInnerHTML={{__html: video_embed_code}} />
              <p>{video_text}</p>
            </div>
          </div>
        </div>
        }
        </div>
      </Fade>
    )
  }
}

SectionPress.propTypes = {
  press: PropTypes.array,
  pressContact: PropTypes.string,
}

SectionPress.defaultProps = {
  press: [],
  pressContact: '',
}

export default SectionPress
