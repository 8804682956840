import React from "react"

class SvgCalendar extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 629 67" preserveAspectRatio="xMinYMin meet">
        <title className={'sr-only'} lang="en">Calendar</title>
        <path fill="none" stroke="#EEC3BE" d="M39.021 65.914c15.921 0 27.666-6.96 33.408-15.573L59.727 43.99c-4.524 6.96-11.223 9.918-20.271 9.918-14.442 0-24.012-6.96-24.012-20.358s9.57-20.358 24.012-20.358c9.048 0 15.66 3.045 20.01 9.831l12.789-6.351C66.687 8.233 54.942 1.186 39.63 1.186c-23.49 0-38.367 12.093-38.367 32.364 0 20.271 14.877 32.364 37.758 32.364zM89.738 64l6.525-12.876h36.8L139.59 64h15.05L122.364 3.1h-15.225L74.86 64h14.877zm37.497-24.186h-25.23l12.615-24.795 12.615 24.795zM221.45 64V51.994h-43.065V3.1h-13.92V64h56.985zm74.033 0V52.342H246.59V38.944h40.107V27.286H246.59V14.758h48.024V3.1h-61.857V64h62.727zm27.052 0V19.978L366.21 64h12.18V3.1h-13.485v41.76L323.841 3.1h-14.79V64h13.485zm106.136 0c22.968 0 37.67-11.484 37.67-30.45S451.64 3.1 428.673 3.1h-32.364V64h32.364zm-1.044-11.832h-17.4V14.932h17.4c14.616 0 24.534 6.09 24.534 18.618 0 12.528-9.918 18.618-24.534 18.618zM483.65 64l6.525-12.876h36.801L533.502 64h15.051L516.276 3.1h-15.225L468.774 64h14.877zm37.497-24.186h-25.23l12.615-24.795 12.615 24.795zM572.213 64V43.294h22.01L611.712 64h16.617l-18.792-21.663c10.092-2.349 16.965-8.787 16.965-19.14C626.5 9.973 615.278 3.1 600.4 3.1H558.38V64h13.833zm27.405-32.19h-27.492V14.758h27.492c7.308 0 12.702 1.74 12.702 8.526s-5.394 8.526-12.702 8.526z"/>
    </svg>
    )
  }
}

export default SvgCalendar
