import React from "react"

class SVG02 extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 67">
        <title className={'sr-only'} lang="en">02 Image</title>
        <path fill="none" strokeWidth="2" stroke="#EEC3BE" d="M38.238 65.914c-22.968 0-36.975-12.093-36.975-32.364 0-20.271 14.007-32.364 36.975-32.364C61.293 1.186 75.3 13.279 75.3 33.55c0 20.271-14.007 32.364-37.062 32.364zm.087-12.006c14.094 0 22.794-6.96 22.794-20.358s-8.7-20.358-22.794-20.358c-14.181 0-22.881 6.96-22.881 20.358s8.7 20.358 22.881 20.358zm47.498-38.367C91.652 7.45 103.31 1.273 117.49 1.273c14.529 0 29.58 6.003 29.58 18.705 0 10.44-9.831 16.269-23.664 20.706-11.31 3.741-19.836 7.83-21.837 12.093h46.806V64H84.518v-6.177c1.74-11.31 12.789-21.054 30.798-26.883 10.005-3.393 17.66-5.655 17.66-10.701 0-5.22-7.916-7.917-15.92-7.917-9.222 0-16.704 3.828-22.968 10.614l-8.265-7.395z" />
      </svg>

    )
  }
}

export default SVG02
