import React from "react"

class SVG01 extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117 67">
        <title className={'sr-only'} lang="en">01 Image</title>
        <path fill="none" stroke="#29554C" strokeWidth="3" d="M38.238 65.914c-22.968 0-36.975-12.093-36.975-32.364 0-20.271 14.007-32.364 36.975-32.364C61.293 1.186 75.3 13.279 75.3 33.55c0 20.271-14.007 32.364-37.062 32.364zm.087-12.006c14.094 0 22.794-6.96 22.794-20.358s-8.7-20.358-22.794-20.358c-14.181 0-22.881 6.96-22.881 20.358s8.7 20.358 22.881 20.358zM101.831 64V18.499c-5.133 3.741-11.571 5.742-18.444 6.873V13.627c7.83-1.131 16.095-5.481 21.837-10.527h10.527V64h-13.92z"/>
      </svg>

    )
  }
}

export default SVG01
