import React from "react"

import Lottie from 'react-lottie';
import animationData from '../../data/tableCat.json'

class ImageCatTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice'
      }
    };

    return <div>
      <Lottie options={defaultOptions}
        height={500}
        width={500}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused}/>
    </div>
  }
}


export default ImageCatTable
