import React, { useState } from 'react';
import { PageContext } from '../context';

const elements = {
  div: 'div',
  li: 'li',
};

function DynamicElem({ type, children, ...props }) {    
  return React.createElement(
    elements[type] || elements.div, 
    props, 
    children
  );
}

const OrderOnlineDropdown = ({links=[], className='', el, buttonClass='btn btn-outline', buttonLabel='Order Online' }) => {
  const [open, setOpen] = useState(false)
  const buttonOpen = open ? 'open' : ''
  return (
    <PageContext.Consumer>
      {({toggleDeliveryModal, togglePickupModal}) => (
      <DynamicElem type={el} className={`${className} dropdown`}>
        <button className={`${buttonClass} ${buttonOpen} dropdown-toggle d-flex align-items-center justify-content-center`} type="button" id="orderDropdownButton" aria-haspopup="true" aria-expanded={open ? 'true' : 'false'} onClick={() => setOpen(!open)}>
          <span className="pr-2">{buttonLabel}</span>
          <svg className="dropdown-caret d-inline-block" xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
            <path fill="none" stroke="#eec3be" d="M19 .828l-9 9-9-9"/>
          </svg>
        </button>
        <div className={`dropdown-menu ${open ? 'show' : ''}`}>
          <button className={'dropdown-item'} onClick={() => {
            setOpen(false)
            togglePickupModal()
          }}>Pick Up</button>
          <button className={'dropdown-item'} onClick={() => {
            setOpen(false)
            toggleDeliveryModal()
          }}>Room Delivery</button>
          {links.map((item) => 
            <a key={item.url} className={'dropdown-item'} href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
          )}
        </div>
      </DynamicElem>
      )}
    </PageContext.Consumer>
  )
}

export default OrderOnlineDropdown
