import React from 'react'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ImageBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: {} }
    this.blockElement = null
  }

  componentDidMount() {
    const blockHeight = this.blockElement.clientWidth

    this.setState({
      blockHeight: blockHeight,
    })
  }

  render() {
    return (
      <a
        className={'insta-block-wrapper d-block'}
        ref={elem => {
          this.blockElement = elem
        }}
        title={'Open in Instagram in new Window'}
        href={this.props.url}
        rel="noopener noreferrer"
        target={'_blank'}>
        <span
          className={'insta-block px-0 '}
          style={{
            height: this.state.blockHeight,
            backgroundImage: `url(${this.props.image})`,
          }}>
          <div
            className={'insta-tag px-3 py-1'}
            style={{
              position: 'absolute',
              zIndex: '5',
              bottom: '0px',
              left: '0px',
              backgroundColor: 'rgba(255,255,255, .5)',
              border: '1px solid #fff',
              borderWidth: '1px 1px 0 0',
              fontSize: '11px',
              color: '29554C',
              fontWeight: 'bold',
            }}>
            @gattaradenver
          </div>
          <div className={'insta-block-inner px-0 text-center align-items-center d-flex'}>
            <p className={'d-block w-100'}>
              See this post <br /> on instagram: <br />
              <br />
              <FontAwesomeIcon icon={faInstagram} fixedWidth size="2x" />
            </p>
          </div>
        </span>
      </a>
    )
  }
}

ImageBlock.defaultProps = {
  url: 'https://wpbakery.com/',
  image: '',
}

export default ImageBlock
