import React from "react"

class SVG04 extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 67">
        <title className={'sr-only'} lang="en">04 Image</title>
        <path fill="none" stroke="#29554C" strokeWidth="3" d="M38.238 65.914c-22.968 0-36.975-12.093-36.975-32.364 0-20.271 14.007-32.364 36.975-32.364C61.293 1.186 75.3 13.279 75.3 33.55c0 20.271-14.007 32.364-37.062 32.364zm.087-12.006c14.094 0 22.794-6.96 22.794-20.358s-8.7-20.358-22.794-20.358c-14.181 0-22.881 6.96-22.881 20.358s8.7 20.358 22.881 20.358zm116.489-13.485v10.788H142.46V64h-13.572V51.211h-46.11V43.12L130.106 3.1h12.354v37.323h12.354zm-53.592 0h27.753V16.846l-27.753 23.577z"/>
      </svg>
    )
  }
}

export default SVG04
