import React from "react"
import ImageTwitterCat from './twitter-cat'


class ImageInstaCat extends React.Component {

  render() {
    return (
      <div style={{
        position: 'absolute',
        top: '-300px',
        left: '0',
        right: '0',
        zIndex: '-1',
      }}>
        <div style={{
          
          transform: `rotate(${(this.props.rotation - 3) / 2}rad) translateX(${(this.props.rotation - 3) * 25}px) translateY(25%)`,
          //transition: `0.2s ease all`,
          transformOrigin: `left bottom`
        }}>
          <div className={'mx-auto'} style={{ maxWidth: '355px' }}>
            <a href={this.props.instagram} target="_blank" rel="noopener noreferrer"><span className="sr-only">View Gattara Instagram</span><ImageTwitterCat /></a>
          </div>
        </div>
      </div>
      
      
    )
  }
}

ImageInstaCat.defaultProps = {
  rotation: 0,
  instagram: '#',
}

export default ImageInstaCat