import React from "react"

class SVGHours extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.498 26.272">
        <title className={'sr-only'} lang="en">Hours</title>
        <path d="M2.158 9.98s-.172-.058-.3-.087l-.395-.09-.226.786.395.09c.128.03.31.053.31.053l-.186.648s-.172-.057-.3-.087l-1.223-.28c-.111-.024-.233-.04-.233-.04l.161-.64c.001-.002.137.041.248.067l.583.133.226-.787-.584-.133C.524 9.587.401 9.57.401 9.57l.162-.638c.001-.003.136.04.248.066l1.222.28c.129.029.31.052.31.052zM3.038 6.176c.41.265.708.266.87.034.165-.234.018-.51-.384-.772-.4-.263-.71-.268-.874-.034-.162.23-.015.512.388.772m.891-1.22c.533.389.587.986.21 1.48-.39.51-.983.61-1.512.225-.502-.367-.59-.978-.206-1.48.388-.507 1.001-.596 1.508-.225M7.26.988c.003-.002.073.138.14.25l.393.654c.344.572.188 1.116-.306 1.415-.577.348-1.1.197-1.415-.327l-.415-.69a3.613 3.613 0 0 0-.151-.229l.558-.35c.002-.002.073.138.14.248l.38.635c.22.363.51.407.742.268.232-.14.327-.421.106-.789l-.377-.627a4.172 4.172 0 0 0-.151-.229zM11.295 1.002c.361-.042.46-.243.421-.426-.039-.179-.222-.356-.521-.321l-.114.013.16.74zm1.552.688l-.625.073a1.435 1.435 0 0 0-.295-.324 1.856 1.856 0 0 0-.363-.225l-.044.005-.228.027.064.298c.028.132.084.31.084.31l-.623.074s-.022-.183-.05-.315L10.493.35a3.28 3.28 0 0 0-.061-.234l.6-.07.296-.035c.517-.06.865.134.945.504.05.233-.009.423-.221.549a2.99 2.99 0 0 1 .44.248c.199.13.297.265.355.378zM17.02.612a1.347 1.347 0 0 0-.546-.258c-.286-.06-.41 0-.433.105-.033.154.186.206.48.298.476.15.704.374.624.75-.085.4-.46.583-1.016.464-.4-.085-.694-.292-.856-.535l.154-.223c.182.21.48.366.73.42.25.054.406.015.431-.1.041-.193-.268-.24-.557-.359-.294-.122-.57-.334-.494-.692.078-.368.422-.535.95-.423.475.101.668.337.672.343l-.14.21z" fill="#e6acae" fillRule="evenodd"/>
        <path d="M19.888 2.374c5.821 3.296 8.154 10.575 5.195 16.642-3.076 6.307-10.706 8.914-17.042 5.824A12.77 12.77 0 0 1 .872 14.009" fill="none" stroke="#29554c" strokeWidth=".265"/>
      </svg>
    )
  }
}

export default SVGHours
