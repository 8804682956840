import React from "react"

class SvgGallery extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 67" preserveAspectRatio="xMinYMin meet">
        <title className={'sr-only'} lang="en">Gallery</title>
        <path fill="none" stroke="#EEC3BE" strokeWidth="1.5" d="M75.213 29.635v22.011c-6.09 8.265-18.096 14.268-34.626 14.268-24.447 0-39.324-12.006-39.324-32.364 0-20.358 14.877-32.364 39.324-32.364 14.964 0 25.578 5.655 31.668 11.571L61.38 20.674c-5.307-5.22-11.484-7.656-20.706-7.656-15.921 0-25.23 6.96-25.23 20.532s9.57 20.532 25.752 20.532c9.135 0 16.617-2.001 20.967-7.221v-5.916H39.195v-11.31h36.018zM149.627 64l-6.525-12.876h-36.801L99.776 64H84.899l32.277-60.9h15.225L164.678 64h-15.051zm-37.584-24.186h25.23l-12.615-24.795-12.615 24.795zm82.07 12.18h43.065V64h-56.985V3.1h13.92v48.894zm73.979 0h43.065V64h-56.985V3.1h13.92v48.894zm73.892.348h48.894V64h-62.727V3.1h61.857v11.658h-48.024v12.528h40.107v11.658h-40.107v13.398zM480.082 64h-16.617l-17.487-20.706h-22.011V64h-13.833V3.1h42.021c14.877 0 26.1 6.873 26.1 20.097 0 10.353-6.873 16.791-16.965 19.14L480.082 64zM423.88 31.81h27.492c7.308 0 12.702-1.74 12.702-8.526s-5.394-8.526-12.702-8.526H423.88V31.81zM487.419 3.1h15.834l20.532 28.536L544.23 3.1h15.747l-29.319 40.107V64h-13.92V43.207L487.419 3.1z"/>
      </svg>

    )
  }
}

export default SvgGallery
