import React from "react"

class SvgCatering extends React.Component  {

  render() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 67" preserveAspectRatio="xMinYMin meet">
      <title className={'sr-only'} lang="en" >Catering</title>
      <path fill="none" stroke="#EEC3BE" strokeWidth="1.5" d="M39.021 65.914c15.921 0 27.666-6.96 33.408-15.573L59.727 43.99c-4.524 6.96-11.223 9.918-20.271 9.918-14.442 0-24.012-6.96-24.012-20.358s9.57-20.358 24.012-20.358c9.048 0 15.66 3.045 20.01 9.831l12.789-6.351C66.687 8.233 54.942 1.186 39.63 1.186c-23.49 0-38.367 12.093-38.367 32.364 0 20.271 14.877 32.364 37.758 32.364zM89.738 64l6.525-12.876h36.8L139.59 64h15.05L122.364 3.1h-15.225L74.86 64h14.877zm37.497-24.186h-25.23l12.615-24.795 12.615 24.795zM188.565 64V15.106h26.622V3.1h-66.99v12.006h26.448V64h13.92zm100.48 0V52.342h-48.893V38.944h40.107V27.286h-40.107V14.758h48.024V3.1h-61.857V64h62.727zm27.401 0V43.294h22.011L355.944 64h16.617L353.77 42.337c10.092-2.349 16.965-8.787 16.965-19.14 0-13.224-11.223-20.097-26.1-20.097h-42.02V64h13.832zm27.405-32.19H316.36V14.758h27.492c7.308 0 12.702 1.74 12.702 8.526s-5.394 8.526-12.702 8.526zM399.18 64V3.1h-13.92V64h13.92zm31.402 0V19.978L474.255 64h12.18V3.1H472.95v41.76L431.886 3.1h-14.79V64h13.485zm109.18 1.914c16.53 0 28.537-6.003 34.627-14.268V29.635H538.37v11.31h22.968v5.916c-4.35 5.22-11.832 7.221-20.967 7.221-16.182 0-25.752-6.96-25.752-20.532s9.309-20.532 25.23-20.532c9.222 0 15.399 2.436 20.706 7.656l10.875-7.917c-6.09-5.916-16.704-11.571-31.668-11.571-24.447 0-39.324 12.006-39.324 32.364 0 20.358 14.877 32.364 39.324 32.364z"/>
    </svg>
    )
  }
}

export default SvgCatering
