import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'

import SVGGetInTouch from './svg/get-in-touch'
import SVG05 from './svg/05'
import SVGPhone from './svg/phone'
import SVGEmail from './svg/email'
import SvgContact from './svg/contact'
import SvgPhoneAnim from './svg/phone-anim'

class SectionContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletterHover: false,
    }
    this.toggleActiveSvg = this.toggleActiveSvg.bind(this)
  }

  toggleActiveSvg() {
    //console.log('toggle hover')
    this.setState({ newsletterHover: !this.state.newsletterHover })
  }

  render() {
    return (
      <div className={'section section-contact section-content'}>
        <Fade>
          <div className={'container'}>
            <div className={'row py-5 justify-content-center'}>
              <div className={'col-lg-5 mb-5 mb-lg-0'}>
                <div className={'svg-git-wrapper d-none d-md-block'} style={{ maxWidth: '350px', margin: 'auto' }}>
                  <SVGGetInTouch hover={this.state.newsletterHover} rotation={this.props.rotation} />
                  <SVG05 />
                </div>

                <div className={'d-block d-md-none mb-3'}>
                  <div className={'text-center mx-auto mb-4'} style={{ maxWidth: '155px' }}>
                    <SVG05 />
                  </div>
                  <div className={'section-title'}>
                    <SvgContact />
                  </div>
                </div>
              </div>
              <Parallax className={'col-md-9 col-lg-6'} y={[-20, 30]}>
                <div className={'menu-block contact-box deep-pink px-3 px-md-5 py-5'}>
                  <div className={'contact-item'}>
                    <SVGPhone />{' '}
                    <span>
                      <a className={'link-underline'} href={'tel:' + this.props.phone}>
                        {this.props.phone}
                      </a>
                    </span>
                  </div>
                  <div className={'contact-item'}>
                    <SVGEmail />{' '}
                    <span>
                      <a className={'link-underline'} href={'mailto:' + this.props.email}>
                        {this.props.email}
                      </a>
                    </span>
                  </div>
                </div>
              </Parallax>
            </div>
            <div className={'row pb-5 justify-content-center align-items-center'}>
              <div className={'col-md-9 col-lg-4 offset-lg-1 text-center'}>
                <div style={{ maxWidth: '250px', margin: 'auto' }}>
                  <SvgPhoneAnim />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

SectionContact.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  instagram: PropTypes.string,
  facebook: PropTypes.string,
}

SectionContact.defaultProps = {
  phone: `(123).000.0000`,
  email: `hello@gattararestaurant.com`,
  instagram: ``,
  facebook: ``,
}

export default SectionContact
