import PropTypes from 'prop-types'
import React from 'react'
//import Sound from 'react-sound'
import Fade from 'react-reveal/Fade'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

import ImageLogo from '../components/images/logo'
import SVGClose from '../components/svg/close'
import ScrollButton from './scroll-button'
import OrderOnlineDropdown from './order-online-dropdown'

const today = new Date()
const year = today.getFullYear()

const Footer = ({ siteTitle, address, phone, email, instagram, facebook, reserveLink, orderOnline }) => (
  <>
    {
      //<Sound url="sound/meow.mp3" autoLoad={true} playStatus={Sound.status.PLAYING} />
    }

    <footer className={'site-footer py-5'} style={{ overflow: 'hidden' }}>
      <div className={'container-fluid px-md-5'}>
        <Fade up cascade delay={100}>
          <div className={'row justify-content-md-center align-items-center'}>
            <div className={'col-lg-4 text-center order-2 order-lg-1 '}>
              <div className={'mx-auto my-2 mb-5 mb-lg-2'} style={{ maxWidth: '220px' }}>
                <ImageLogo />
              </div>
            </div>

            <div className={'col-lg-4 text-center order-3 order-lg-2'}>
              <div dangerouslySetInnerHTML={{ __html: address }} />
              <p>
                <a className={'link-underline'} title={`Email`} href={'mailto:' + email}>
                  {email}
                </a>
              </p>
              <p>
                <a className={'link-underline'} title={`Phone`} href={'tel:' + phone}>
                  {phone}
                </a>
              </p>

              <ul className={'social-media-list list-inline my-5 my-lg-4'}>
                <li className={'list-inline-item'}>
                  <a className={' social-link d-block py-3 px-3'} href={instagram}>
                    <FontAwesomeIcon icon={faInstagram} fixedWidth size="2x" />
                    <span className={'sr-only'}>Instagram</span>
                  </a>
                </li>
                <li className={'list-inline-item'}>
                  <a className={' social-link d-block py-3 px-3'} href={facebook}>
                    <FontAwesomeIcon icon={faFacebookF} fixedWidth size="2x" />
                    <span className={'sr-only'}>Facebook</span>
                  </a>
                </li>
              </ul>

              <ul className={'list-inline mb-4'}>
                <li className={'list-inline-item d-block d-md-inline-block px-2 text-uppercase'}>
                  <a
                    style={{ color: '#eec3be' }}
                    className={'link-underline'}
                    title={`Open Privacy Policy in a new window.`}
                    href="https://www.warwickhotels.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </li>
                <li className={'list-inline-item d-block d-md-inline-block px-2 text-uppercase'}>
                  <a
                    style={{ color: '#eec3be' }}
                    className={'link-underline'}
                    href="https://warwickhotels.com/denver/"
                    target="_blank"
                    title={`Open Warwick Hotels website in new window.`}
                    rel="noopener noreferrer">
                    Warwick Hotels
                  </a>
                </li>
              </ul>

              <small className={'d-block'}>&copy; {year} Warwick Hotels</small>
              <small className={'d-block'}>
                Site designed and built by{' '}
                <a
                  href="https://www.hellowildern.com/"
                  title={`Open Wildern website in a new window.`}
                  style={{ color: '#eec3be' }}>
                  Wildern
                </a>
              </small>
            </div>

            <div className={'col-lg-4 text-center text-lg-right order-1 order-lg-3 mb-5'}>
              <div className={'mb-3'}>
                <OrderOnlineDropdown className={'d-inline-block mb-3'} links={orderOnline} />
                <br/>
                <a className={'btn btn-outline mb-4 mb-lg-0 '} href={reserveLink} title={`Reserve a table`}>
                  Reserve
                </a>
              </div>

              <ScrollButton target={'.section-hero'} className={'btn btn-link btn-x'}>
                <div className={'close-btn d-inline-block'} style={{ maxWidth: '12px' }}>
                  <SVGClose />
                </div>
                <span style={{ color: '#124e4b' }}>Back to Top </span>
              </ScrollButton>
            </div>
          </div>
        </Fade>
      </div>
    </footer>
  </>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
