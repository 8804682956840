import React from "react"

class SVG07 extends React.Component  {
  render() {
    return (
      <div className={'mb-3 mb-lg-0 mx-auto mr-md-0 ml-md-auto'}
        style={{
        maxWidth: '150px',
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 69">
          <title>07</title>
          <path fill="none" stroke="#29554C" strokeWidth={4} d="M38.975 66.728c23.055 0 37.062-12.093 37.062-32.364C76.037 14.093 62.03 2 38.975 2 16.007 2 2 14.093 2 34.364c0 20.271 14.007 32.364 36.975 32.364zm.087-12.006c-14.181 0-22.881-6.96-22.881-20.358s8.7-20.358 22.881-20.358c14.094 0 22.794 6.96 22.794 20.358s-8.7 20.358-22.794 20.358zm73.598 10.092l34.017-51.765V3.914H83.602v11.658h46.11L96.739 64.814h15.92z"/>
        </svg>
      </div>
    )
  }
}

export default SVG07
