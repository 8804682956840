import React from 'react'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'
import SvgGallery from './svg/gallery'
import Svg03 from './svg/03'
import ImageBlock from './image-block'
import ImageTwitterCat from './images/twitter-cat'
import ImageInstaCat from './images/insta-cat'

const SectionGallery = ({ data, instagram, rotation }) => (
  <div className={'section-content section-gallery'}>
    <Fade big delay={500}>
      <div className={'container'}>
        <div className={'row no-gutters'}>
          <div className={'col-md-12 col-lg-3 mt-md-5 mb-2 mb-lg-5'}>
            <div className={'mx-auto mb-4'} style={{ maxWidth: '155px' }}>
              <Svg03 />
            </div>
            <div className={'d-none d-lg-block'}>
              <ImageBlock
                url={'meta' in data[0] ? data[0].meta.instagram_link : '#'}
                image={'_embedded' in data[0] ? data[0]['_embedded']['wp:featuredmedia'][0].source_url : ''}
              />
            </div>
          </div>
          <div className={'col-md-12 col-lg-9'}>
            <div className={'row no-gutters justify-content-center'}>
              <div className={'col-12 order-2 order-lg-1 xs-row-container'}>
                <div className={'row no-gutters align-items-end xs-row-half'}>
                  <div className={'col-md-5 d-lg-none order-2'}>
                    <ImageBlock
                      url={'meta' in data[0] ? data[0].meta.instagram_link : '#'}
                      image={'_embedded' in data[0] ? data[0]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                  <div className={'col-md-3 order-3'}>
                    <ImageBlock
                      url={'meta' in data[1] ? data[1].meta.instagram_link : '#'}
                      image={'_embedded' in data[1] ? data[1]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                  <div className={'col-md-4 order-4'}>
                    <ImageBlock
                      url={'meta' in data[2] ? data[2].meta.instagram_link : '#'}
                      image={'_embedded' in data[2] ? data[2]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                  <div className={'col-lg-5 order-1 order-lg-5 d-none d-lg-block'}>
                    <ImageInstaCat rotation={rotation} instagram={instagram} />
                  </div>
                </div>
                <div className={'row no-gutters xs-row-half'}>
                  <div className={'col-md-4'}>
                    <ImageBlock
                      url={'meta' in data[3] ? data[3].meta.instagram_link : '#'}
                      image={'_embedded' in data[3] ? data[3]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                  <div className={'col-md-3'}>
                    <ImageBlock
                      url={'meta' in data[4] ? data[4].meta.instagram_link : '#'}
                      image={'_embedded' in data[4] ? data[4]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                  <div className={'col-md-5 col-lg-4'}>
                    <ImageBlock
                      url={'meta' in data[5] ? data[5].meta.instagram_link : '#'}
                      image={'_embedded' in data[5] ? data[5]['_embedded']['wp:featuredmedia'][0].source_url : ''}
                    />
                  </div>
                </div>
              </div>
              <div className={'col-12 order-1 order-lg-2'}>
                <Parallax className={'pb-5'} x={[0, -8]}>
                  <div className={'mx-auto ml-lg-auto mr-lg-0 section-title'} style={{ maxWidth: '600px' }}>
                    <SvgGallery />
                  </div>
                </Parallax>
              </div>

              <div className={'col-8 order-3 order-lg-2 d-block d-lg-none mt-5'}>
                <div className={'mx-auto'} style={{ maxWidth: '355px' }}>
                  <a href={instagram} target="_blank" rel="noopener noreferrer">
                    <ImageTwitterCat />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  </div>
)

export default SectionGallery
