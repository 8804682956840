import React from 'react'
import Fade from 'react-reveal/Fade'

import SVGClose from './svg/close'
import SVGLine from './svg/line'
import Menu from './menu'

const SidebarNav = () => (
  <div className={'site-menu'}>
    <Fade>
      <div className="menu-wrapper">
        <div className="menu-line">
          <span id="indicator" className="indicator" />
          <span className="line">
            <SVGLine />
          </span>
          <span className="small-x">
            <SVGClose />
          </span>
        </div>
        <Menu />
      </div>
    </Fade>
  </div>
)

export default SidebarNav
