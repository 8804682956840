import React from "react"

class SVGEmail extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 59" width="76" height="60">
        <title className={'sr-only'} lang="en">Email</title>
        <path fill="#E6ACAE" d="M10.406 54.714l.041-3.504c.013-1.12-.149-2.274-.523-3.11l2.38.332a33.022 33.022 0 0 0-.107 2.302l-.089 7.568s-1.839-.118-2.654-.127l-6.624-.078c-.704-.008-1.92.042-1.92.042l.078-6.688c.008-.72-.015-1.424-.071-2.145l2.1-.28c-.345.733-.484 1.627-.496 2.667l-.034 2.928 2.832.033.044-3.792 1.773.23a34.317 34.317 0 0 0-.378 3.579l3.648.043zm10.427-28.237l-2.05 2.94c-.494-.383-.9-.667-1.58-1.12l-3.61-2.402.967 3.172c.257.862.546 1.707.917 2.824-1.123.017-1.998.07-3.034.128l-3.262.202c1.123.822 2.4 1.732 3.52 2.533.66.48 1.615 1.068 1.615 1.068L13 37.712c-.432-.36-.965-.77-1.534-1.187a2321.066 2321.066 0 0 1-6.348-4.602c-.398-.297-.787-.55-1.15-.783l1.31-1.992.013.009-.004-.023c.59.022 1.128.006 1.719-.03l5.524-.342-1.523-5.256c-.161-.58-.368-1.095-.575-1.61l.045-.008-.026-.018.988-1.613c-.004-.022.722.504 1.3.906l6.452 4.286a46.159 46.159 0 0 0 1.643 1.028zM32.453 6.33l5.505 5.978c.601.654.955 1.04 1.677 1.601l-3.838 1.338c-.398-.607-.717-.988-1.256-1.562l-.21-.215-5.243 1.827c-.051.73-.094 1.626-.03 2.298l-2.432.847c.136-.776.253-1.85.308-2.615l.57-7.653c.043-.558.078-1.332.05-1.848l3.66-1.41c.35.42.884 1.03 1.239 1.414zm.717 5.9l-3.527-3.75-.416 5.126 3.943-1.374zM51.376.276l3.554.481c.015.003-.197.86-.316 1.553l-1.311 7.632a27.642 27.642 0 0 0-.234 1.925l-3.61-.621s.283-1.088.422-1.892l1.311-7.632c.12-.694.184-1.446.184-1.446zM68.249 14.53l3.296 1.94c.965.568 2.15.857 3.143.81l-1.656 2.182a33.022 33.022 0 0 0-1.945-1.238l-6.797-4.002s1.016-1.536 1.43-2.24l3.36-5.708c.358-.606.887-1.632.887-1.632l3.168 1.717A26.948 26.948 0 0 0 72.08 8.02l-3.832 6.508z"/>
      </svg>
    )
  }
}

export default SVGEmail
