import PropTypes from 'prop-types'
import React from 'react'

class ScrollButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.doScroll = this.doScroll.bind(this)
  }

  handleClick() {
    if (this.props.target) {
      var target = document.querySelector(this.props.target)
      if (target) {
        var y = target.offsetTop
        this.doScroll(y, 800)
      }
    }
    this.props.callback()
  }

  doScroll(elementY, duration) {
    var startingY = window.pageYOffset
    var diff = elementY - startingY
    var start

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1)

      window.scrollTo(0, startingY + diff * percent)

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  }

  render() {
    return (
      <button className={this.props.className} onClick={this.handleClick} alt={this.props.alt}>
        {this.props.children}
      </button>
    )
  }
}

ScrollButton.propTypes = {
  target: PropTypes.string,
  className: PropTypes.string,
  callback: PropTypes.func,
  alt: PropTypes.string,
}

ScrollButton.defaultProps = {
  target: ``,
  alt: ``,
  className: 'btn btn-link',
  callback: () => {},
}

export default ScrollButton
