import React from "react"

class SVG03 extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 67">
        <title className={'sr-only'} lang="en">03 Image</title>
        <path fill="none" stroke="#EEC3BE" strokeWidth="2" d="M38.238 65.914c-22.968 0-36.975-12.093-36.975-32.364 0-20.271 14.007-32.364 36.975-32.364C61.293 1.186 75.3 13.279 75.3 33.55c0 20.271-14.007 32.364-37.062 32.364zm.087-12.006c14.094 0 22.794-6.96 22.794-20.358s-8.7-20.358-22.794-20.358c-14.181 0-22.881 6.96-22.881 20.358s8.7 20.358 22.881 20.358zm61.973-8.874c4.263 6.177 12.006 9.657 22.272 9.657 9.048 0 17.661-2.262 17.661-8.961 0-6.786-8.352-8.178-18.966-8.178h-9.048V27.286h9.048c8.874 0 17.139-1.914 17.139-7.482 0-5.655-8.526-7.395-16.182-7.395-10.44 0-17.661 3.915-21.576 9.309l-10.092-6.699c6.351-8.178 17.313-13.746 31.668-13.746 15.921 0 30.363 5.568 30.363 16.965 0 7.482-6.525 13.05-14.616 13.92 8.178 1.218 16.965 5.481 16.965 14.877 0 12.093-14.181 18.792-32.016 18.792-15.138 0-26.883-5.742-33.669-14.442l11.049-6.351z"/>
      </svg>
    )
  }
}

export default SVG03
