import React from "react"

class SVGLine extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 475">
        <title className={'sr-only'} lang="en">Line Image</title>
        <path fill="none" stroke="#FAECE7" strokeDasharray="5" strokeLinecap="square" d="M1 0v477.782"/>
      </svg>
    )
  }
}

export default SVGLine
