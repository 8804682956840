import React from 'react'

import ScrollButton from './scroll-button'
import SvgAbout from './svg/about'
import SvgMenus from './svg/menus'
import SvgContact from './svg/contact'
import SvgGallery from './svg/gallery'
import SvgLocation from './svg/location'
import SvgPress from './svg/press'
import SvgCalendar from './svg/calendar'
import SvgCatering from './svg/catering'

const sections = [
  {
    id: 'about',
    name: 'About',
    svg: SvgAbout,
  },
  {
    id: 'menus',
    name: 'Menus',
    svg: SvgMenus,
  },
  {
    id: 'gallery',
    name: 'Gallery',
    svg: SvgGallery,
  },
  {
    id: 'location',
    name: 'Location',
    svg: SvgLocation,
  },
  {
    id: 'contact',
    name: 'Contact',
    svg: SvgContact,
  },
  {
    id: 'catering',
    name: 'Catering',
    svg: SvgCatering,
  },
  {
    id: 'press',
    name: 'Press',
    svg: SvgPress,
  },
]

const Menu = ({ callback }) => (
  <ol className={'side-menu px-3'}>
    {sections.map(section => {
      let SvgTag = section.svg
      return (
        <li key={section.id} className={'menu-item menu-item-' + section.id}>
          <ScrollButton
            target={'.section-' + section.id}
            className={'menu-link btn btn-link'}
            callback={callback}
            alt={section.name}>
            <SvgTag />
          </ScrollButton>
        </li>
      )
    })}
    <li className={'menu-item menu-item-calendar'}>
      <a className={'menu-link btn btn-link'} href={'/calendar/'} target={'_blank'} rel="noopener noreferrer">
        <SvgCalendar />
      </a>
    </li>
  </ol>
)

export default Menu
