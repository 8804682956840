import React from "react"

class SVGPhone extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 54" width="96" height="60">
        <title className={'sr-only'} lang="en">Phone</title>
        <path fill="#E6ACAE" d="M1.075 46.965c.378-3.016 2.01-4.586 4.28-4.301 2.27.284 3.502 1.905 3.037 5.62l-.167 1.334 1.826.228c.81.102 1.933.146 1.933.146l-.455 3.635s-1.1-.234-1.91-.335l-7.747-.97c-.698-.088-1.452-.117-1.452-.117l.655-5.24zm5.69 2.47l.04-.317c.264-2.112-.762-3.063-1.89-3.204-1.095-.137-2.389.54-2.607 2.286l-.084.667 4.54.568zm15.384-25.102L19.8 27.147s-.798-.792-1.425-1.315l-1.916-1.599-2.85 3.415 1.916 1.6a27.918 27.918 0 0 0 1.548 1.166l-2.347 2.813s-.799-.791-1.425-1.314l-5.946-4.962c-.54-.45-1.159-.883-1.159-.883l2.198-2.834c.01-.012.645.6 1.186 1.052l2.837 2.368 2.85-3.415-2.837-2.368c-.54-.451-1.16-.884-1.16-.884l2.199-2.834c.01-.012.645.6 1.185 1.052l5.946 4.962a27.918 27.918 0 0 0 1.548 1.166zM32.03 3.91c3.538-1.015 6.59.574 7.533 3.865.992 3.461-.891 6.298-4.336 7.285-3.553 1.019-6.555-.451-7.538-3.88-.935-3.262.85-6.27 4.341-7.27zm.23 1.731c-1.536.507-1.888 2.24-1.016 4.702.886 2.51 2.212 3.477 3.763 2.966 1.567-.516 1.893-2.224 1.036-4.691-.857-2.467-2.216-3.492-3.783-2.977zm32.189-.729l-2.337 7.383a55.86 55.86 0 0 0-.538 1.86l-2.334-.738a16.976 16.976 0 0 0-.799-1.931l-2.973-6.143-1.395 4.408c-.246.778-.493 1.875-.493 1.875l-2.227-.705s.43-1.039.676-1.817l2.336-7.383c.213-.671.388-1.438.388-1.438l2.48.701-.03.041.046-.036c.206.569.458 1.152.74 1.745l2.938 6.2 1.477-4.669c.213-.67.388-1.438.388-1.438l2.17.62c.016.005-.287.748-.513 1.465zm11.233 14.213l2.56 2.392c.818.765 1.78 1.423 2.65 1.71l-1.85 1.534a33.022 33.022 0 0 0-1.628-1.631l-5.53-5.167s1.326-1.279 1.883-1.875l4.523-4.84a48.735 48.735 0 0 0 1.264-1.447l4.887 4.566c.526.492 1.062.95 1.632 1.394l-1.21 1.739c-.308-.748-.875-1.453-1.635-2.163l-2.14-2-1.933 2.07 2.771 2.589-1.364 1.156a34.317 34.317 0 0 0-2.39-2.693l-2.49 2.666z"/>
      </svg>
    )
  }
}

export default SVGPhone
