import React from "react"

class SVG05 extends React.Component  {

  render() {
    return (
      <svg className="svg-five" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 67">
        <title className={'sr-only'} lang="en">05 Image</title>
        <path fill="none" stroke="#29554C" strokeWidth="3" d="M38.238 65.797c-22.968 0-36.975-12.093-36.975-32.364 0-20.27 14.007-32.364 36.975-32.364C61.293 1.07 75.3 13.162 75.3 33.433c0 20.271-14.007 32.364-37.062 32.364zm.087-12.006c14.094 0 22.794-6.96 22.794-20.358s-8.7-20.358-22.794-20.358c-14.181 0-22.881 6.96-22.881 20.358s8.7 20.358 22.881 20.358zm54.197-9.048c4.437 4.263 13.659 9.396 25.839 9.396 10.614 0 18.183-2.436 18.183-9.483 0-6.96-7.57-9.918-19.227-9.918-6.612 0-13.224 1.131-20.271 4.002L86.78 34.738 92 2.983h52.46V14.12h-41.933l-2.523 15.573c6.96-3.828 14.007-5.568 22.359-5.568 14.703 0 28.449 6.612 28.449 20.706 0 11.484-10.527 20.967-32.451 20.967-14.616 0-26.361-5.046-34.365-12.963l8.526-8.09z"/>
      </svg>
    )
  }
}

export default SVG05
