import React from "react"

class SVGLocationRounded extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.551 26.896">
        <title className={'sr-only'} lang="en">Location</title>
        <path d="M1.78 16.482a1.079 1.079 0 0 0-.306-.499l.49-.06c.023.117.056.256.096.399l.378 1.357s-.326.062-.468.097l-1.155.283c-.122.03-.32.09-.32.09L.3 17.537c.114-.02.234-.046.346-.073l1.317-.323-.184-.658zM.96 12.79c.453.006.7-.16.734-.46.033-.302-.208-.466-.654-.474-.446-.01-.704.16-.737.462-.033.297.21.467.656.472m.206-1.593c.61.048.913.551.817 1.206-.1.676-.547 1.1-1.151 1.052-.574-.045-.912-.542-.814-1.206.1-.673.568-1.098 1.148-1.052M2.478 6.774a.904.904 0 0 0-.443.413.593.593 0 0 0 .236.84c.37.2.73.09.941-.3a.727.727 0 0 0 .056-.572l.267.003c.068.16.16.449-.102.932-.314.579-.893.78-1.42.496-.54-.293-.602-.927-.327-1.434.206-.38.442-.537.627-.627zM5.434 3.628l.206.882.565-.497zm1.55.072c.133.068.21.108.358.152l-.55.484a1.572 1.572 0 0 0-.287-.174L6.46 4.14l-.752.662c.031.125.072.277.118.385l-.348.307a5.43 5.43 0 0 0-.09-.457l-.317-1.314a3.008 3.008 0 0 0-.09-.311l.517-.485c.079.047.198.112.276.152zM10.036 2.035c.054.155.109.313.174.464l-.617.217s-.092-.315-.143-.463l-.413-1.186-.169.059a1.162 1.162 0 0 0-.426.239L8.37.99c.126-.032.247-.069.37-.112l.992-.35c.122-.043.24-.09.358-.144l.176.338a1.151 1.151 0 0 0-.482.081L9.627.86zM13.83 0c.003 0-.009.148-.01.266l-.014 1.3a4.895 4.895 0 0 0 .012.325l-.595.02s.017-.188.019-.325l.014-1.301c.001-.118-.008-.244-.008-.244z" fill="#e6acae" fillRule="evenodd"/>
        <path d="M17.307 1.596c-.125.463-.027.741.255.824.285.084.508-.136.634-.59.124-.454.027-.745-.258-.828-.28-.082-.509.139-.63.593m1.496.47c-.206.614-.776.843-1.388.637-.63-.212-.926-.736-.722-1.345.195-.58.767-.844 1.388-.636.628.211.918.759.722 1.344" mask="url(#location-b)" fill="#e6acae" fillRule="evenodd"/>
        <path d="M22.375 5.279c-.091.106-.21.257-.21.257l-.33-.276a2.786 2.786 0 0 0 0-.36l-.072-1.177-.52.603c-.09.106-.204.263-.204.263l-.313-.264s.138-.135.23-.241l.87-1.01c.078-.092.158-.201.158-.201l.354.28-.008.005.01-.003c-.004.104-.002.214.004.327l.063 1.183.55-.638c.079-.092.158-.202.158-.202l.31.247c.002.002-.096.1-.18.197z" fill="#e6acae" fillRule="evenodd"/>
        <path d="M24.506 7.046c3.713 6.009 1.859 13.95-4.142 17.736-6 3.787-13.875 1.986-17.589-4.023" fill="none" stroke="#29554c" strokeWidth=".265"/>
        </svg>
    )
  }
}

export default SVGLocationRounded
