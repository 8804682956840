import React from 'react'
import PropTypes from 'prop-types'

import InnerHTML from 'dangerously-set-html-content'
import Modal from 'react-bootstrap/Modal'

import Footer from './footer'
import Header from './header'
import NavMobile from '../components/nav-mobile'
import PopupModal from './popup'
import OrderOnlineDropdown from './order-online-dropdown'
import { PageContext } from '../context'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.togglePickupModal = () => {
      this.setState({
        showPickupModal: !this.state.showPickupModal,
        showDeliveryModal: false
      })
    }
  
    this.toggleDeliveryModal = () => {
      this.setState({
        showDeliveryModal: !this.state.showDeliveryModal,
        showPickupModal: false
      })
    }

    this.state = {
      active: false,
      navBarActiveClass: '',
      showPickupModal: false,
      showDeliveryModal: false,
      togglePickupModal: this.togglePickupModal,
      toggleDeliveryModal: this.toggleDeliveryModal,
    }
    this.blockElement = null
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'nav-is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    // eslint-disable-next-line
    const { children, address, email, phone, instagram, facebook, reserveLink, orderOnline, rotation, data, pickup_content, delivery_content } = this.props

    const { showPickupModal, showDeliveryModal } = this.state

    return (
      <PageContext.Provider value={this.state} >
        <div id="page-wrap" className={` ${this.state.navBarActiveClass}`}>
          <div className="toggle-wrapper">
            <button
              className={`position-fixed button navbar-toggler ${this.state.navBarActiveClass}`}
              data-target="page-wrap"
              onClick={() => this.toggleHamburger()}>
              <span className={'icon-bar top-bar'} />
              <span className={'icon-bar middle-bar'} />
              <span className={'icon-bar bottom-bar'} />
              <span className={'sr-only'}>Toggle navigation</span>
            </button>
          </div>
          <NavMobile callback={this.toggleHamburger} reserveLink={reserveLink} orderOnline={orderOnline} />
          <div className={`d-lg-none position-fixed fixed-reserve-btn-group`}>
            {orderOnline && (
              <OrderOnlineDropdown className="d-inline-block" links={[...orderOnline, {name:'Reserve a Table', url: reserveLink}]} buttonClass="btn btn-outline reserve-btn-xs py-2 px-3" buttonLabel="Order / Reserve" />
            )}
          </div>

          <Header
            reserveLink={reserveLink}
            orderOnline={orderOnline}
            instagram={instagram}
            facebook={facebook}
            rotation={rotation}
          />
          <div className={'site-content'}>
            <main className={'site-main'}>{children}</main>
            <PopupModal url={data.popup_url} image={data.popup_image} html={data.popup_html} />
            <Footer
              address={address}
              email={email}
              phone={phone}
              facebook={facebook}
              instagram={instagram}
              reserveLink={reserveLink}
              orderOnline={orderOnline}
            />
          </div>
          <Modal
            show={showPickupModal}
            onHide={() => this.setState({showPickupModal: false})}
            centered
            backdropClassName={'backdropGreen'}
            dialogClassName={'dialogGreen popupModal'}>
            <Modal.Header closeButton><Modal.Title>Pick Up</Modal.Title></Modal.Header>
            <Modal.Body>
              {pickup_content &&
              <div dangerouslySetInnerHTML={{__html: pickup_content}} />
              }
            </Modal.Body>
          </Modal>
          <Modal
            show={showDeliveryModal}
            onHide={() => this.setState({showDeliveryModal: false})}
            centered
            backdropClassName={'backdropGreen'}
            dialogClassName={'dialogGreen popupModal'}>
            <Modal.Header closeButton><Modal.Title>Delivery</Modal.Title></Modal.Header>
            <Modal.Body>
              {delivery_content &&
              <div dangerouslySetInnerHTML={{__html: delivery_content}} />
              }
            </Modal.Body>
          </Modal>
          <InnerHTML html={data.extra_scripts} />
        </div>
      </PageContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
