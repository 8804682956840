import React from "react"

class SvgLocation extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 606 67" preserveAspectRatio="xMinYMin meet">
        <title className={'sr-only'} lang="en">Location</title>
        <path fill="none" stroke="#EEC3BE" strokeWidth="1.5" d="M15.098 51.994h43.065V64H1.178V3.1h13.92v48.894zm92.684 13.92c-23.751 0-38.715-12.006-38.715-32.364 0-20.358 14.964-32.364 38.715-32.364 23.751 0 38.715 12.006 38.715 32.364 0 20.358-14.964 32.364-38.715 32.364zm0-12.006c15.051 0 24.534-7.308 24.534-20.358s-9.483-20.358-24.534-20.358c-15.051 0-24.534 7.308-24.534 20.358s9.396 20.358 24.534 20.358zm92.771 0c9.048 0 15.747-2.958 20.271-9.918l12.702 6.351c-5.742 8.613-17.487 15.573-33.408 15.573-22.881 0-37.758-12.093-37.758-32.364 0-20.271 14.877-32.364 38.367-32.364 15.312 0 27.057 7.047 32.625 15.486l-12.789 6.351c-4.35-6.786-10.962-9.831-20.01-9.831-14.442 0-24.012 6.96-24.012 20.358s9.57 20.358 24.012 20.358zM306.374 64l-6.525-12.876h-36.801L256.523 64h-14.877l32.277-60.9h15.225L321.425 64h-15.051zM268.79 39.814h25.23l-12.615-24.795-12.615 24.795zM387.661 3.1v12.006h-26.622V64h-13.92V15.106h-26.448V3.1h66.99zm30.74 60.9h-13.92V3.1h13.92V64zm58.406 1.914c-23.751 0-38.715-12.006-38.715-32.364 0-20.358 14.964-32.364 38.715-32.364 23.751 0 38.715 12.006 38.715 32.364 0 20.358-14.964 32.364-38.715 32.364zm0-12.006c15.051 0 24.534-7.308 24.534-20.358s-9.483-20.358-24.534-20.358c-15.051 0-24.534 7.308-24.534 20.358s9.396 20.358 24.534 20.358zM591.154 3.1h13.485V64h-12.18l-43.674-44.022V64H535.3V3.1h14.79l41.064 41.76V3.1z"/>
      </svg>

    )
  }
}

export default SvgLocation
