import React from 'react'
import Headroom from 'react-headroom'
import Fade from 'react-reveal/Fade'

import SVGLogo from './svg/logo'
import SVGLogoDark from './svg/logo-dark'
import NavbarHero from './navbar'
import ScrollButton from './scroll-button'

const Header = ({ reserveLink, orderOnline, facebook, instagram, rotation }) => (
  <Headroom className={'text-center'}>
    <Fade down ssrReveal>
      <div className={'site-logo-wrapper mx-5 mt-5 mt-md-0 pt-5 pt-md-0'}>
        <NavbarHero reserveLink={reserveLink} orderOnline={orderOnline} instagram={instagram} facebook={facebook} />

        <div className={'site-logo-main mx-auto mt-3 mt-lg-5'}>
          <SVGLogoDark rotation={rotation} />
        </div>

        <div className={'site-logo-pinned d-none d-lg-block text-center'}>
          <ScrollButton className={'btn btn-link'} target=".section-hero">
            <div className={'py-3'}>
              <SVGLogo />
            </div>
          </ScrollButton>
        </div>
      </div>
    </Fade>
  </Headroom>
)

export default Header
