import React from "react"

class SvgAbout extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407 67" preserveAspectRatio="xMinYMin meet"  alt="About">
        <title className={'sr-only'} lang="en">About</title>
        <path fill="none" stroke="#EEC3BE" d="M65.902 64l-6.525-12.876H22.576L16.051 64H1.174L33.451 3.1h15.225L80.953 64H65.902zM28.318 39.814h25.23L40.933 15.019 28.318 39.814zm114.575-7.656c9.57.522 17.052 5.481 17.052 15.138 0 10.788-9.222 16.704-23.838 16.704H90.78V3.1h41.847c14.616 0 23.838 5.742 23.838 15.573 0 6.96-4.524 12.876-13.572 13.485zm-38.367-18.096v13.746h25.839c7.569 0 12.267-1.218 12.267-6.873 0-5.568-4.698-6.873-12.267-6.873h-25.84zm28.71 38.976c7.83 0 12.44-1.218 12.44-7.308 0-6.177-4.61-7.308-12.44-7.308h-28.71v14.616h28.71zm75.163 12.876c-23.75 0-38.715-12.006-38.715-32.364 0-20.358 14.964-32.364 38.715-32.364 23.751 0 38.715 12.006 38.715 32.364 0 20.358-14.964 32.364-38.715 32.364zm0-12.006c15.051 0 24.534-7.308 24.534-20.358S223.45 13.192 208.4 13.192c-15.05 0-24.534 7.308-24.534 20.358s9.396 20.358 24.534 20.358zM314.622 3.1h13.92v32.19c0 18.27-12.093 30.624-34.191 30.624-22.098 0-34.191-12.354-34.191-30.624V3.1h13.92v31.146c0 12.18 7.134 19.488 20.27 19.488 13.138 0 20.272-7.308 20.272-19.488V3.1zm90.997 0v12.006h-26.622V64h-13.92V15.106H338.63V3.1h66.99z"/>
      </svg>

    )
  }
}

export default SvgAbout
