import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Modal from 'react-bootstrap/Modal'

import { Parallax } from 'react-scroll-parallax'

import SVG01 from '../components/svg/01'
import SvgAboutVertical from '../components/svg/about-vertical'
import ImageCatTable from '../components/images/table-cat'

class SectionAbout extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    return (
      <div className={'section-content section-about'}>
        <Fade>
          <div className={'container'}>
            <div className={'row pb-md-5'}>
              <div className={'col-lg-4 text-center'}>
                <Parallax y={[0, 40]} className={'mx-auto mb-4'} styleOuter={{ maxWidth: '130px' }}>
                  <SVG01 />
                </Parallax>
              </div>
            </div>
            <div className={'row'}>
              <Parallax className={'col-md-4 pt-md-5'} y={[0, -40]}>
                <div className={'mx-auto mr-md-5 mt-md-5'}>
                  <SvgAboutVertical />
                </div>
              </Parallax>

              <Parallax
                className={'col-md-8 pl-4 mb-md-5'}
                y={[-20, 10]}
                styleInner={{ willChange: 'transform', transition: 'transform 0s linear 0s' }}>
                <div className={'px-md-5 pb-md-5 entry-content-wrapper'}>
                  <div className={'entry-content mb-4'} dangerouslySetInnerHTML={{ __html: this.props.about }} />
                  <button className={'btn btn-outline'} onClick={this.handleShow}>
                    Meet the Chef
                  </button>
                </div>
                <div className={'mx-auto ml-md-auto mr-md-0 mb-md-4 tableCat-wrapper'} style={{ maxWidth: '500px' }}>
                  <ImageCatTable />
                </div>
              </Parallax>
            </div>
          </div>

          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            size="xl"
            centered
            backdropClassName={'backdropGreen'}
            dialogClassName={'dialogGreen'}>
            <Modal.Header closeButton>
              <Modal.Title>Meet the Chef</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={'row'}>
                <div className={'col-lg-4'}>
                  <div className={'image-wrapper-chef'}>
                    <img
                      alt={'Meet the Chef'}
                      src={this.props.about_chef_photo}
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>
                <div className={'col-lg-8'}>
                  <div className={'entry-content mb-4'} dangerouslySetInnerHTML={{ __html: this.props.about_chef }} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Fade>
      </div>
    )
  }
}

SectionAbout.propTypes = {
  about: PropTypes.string,
}

SectionAbout.defaultProps = {
  about: ``,
}

export default SectionAbout
