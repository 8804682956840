import React from "react"

class SvgPress extends React.Component  {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383 67" preserveAspectRatio="xMinYMin meet">
        <title className={'sr-only'} lang="en">Press</title>
        <path fill="none" stroke="#EEC3BE" d="M15.098 64V44.338h27.753c15.66 0 26.1-7.134 26.1-20.619 0-13.311-10.44-20.619-26.1-20.619H1.178V64h13.92zM41.72 32.854H15.098V14.758H41.72c7.83 0 13.05 2.088 13.05 9.048s-5.22 9.048-13.05 9.048zM95.134 64V43.294h22.01L134.633 64h16.617l-18.792-21.663c10.092-2.349 16.965-8.787 16.965-19.14 0-13.224-11.223-20.097-26.1-20.097H81.3V64h13.833zm27.405-32.19H95.047V14.758h27.492c7.308 0 12.702 1.74 12.702 8.526s-5.394 8.526-12.702 8.526zM226.673 64V52.342H177.78V38.944h40.107V27.286H177.78V14.758h48.024V3.1h-61.857V64h62.727zm45.236 1.914c21.576 0 32.973-8.352 32.973-20.358 0-13.398-13.66-17.487-33.06-19.053-12.093-.957-20.793-1.479-20.793-6.699 0-5.568 10.092-7.047 18.357-6.96 9.918.087 18.183 2.175 24.882 7.308l7.656-9.657c-7.743-6.003-19.227-9.309-32.538-9.309-18.792 0-32.364 6.96-32.364 19.662 0 13.05 16.182 16.878 33.582 18.357 14.007 1.218 20.27 2.61 20.27 7.656 0 5.046-6.263 7.395-18.617 7.395-12.441 0-22.098-3.219-29.58-10.092l-8.004 9.483c9.309 7.917 21.837 12.267 37.236 12.267zm77.512 0c21.576 0 32.973-8.352 32.973-20.358 0-13.398-13.659-17.487-33.06-19.053-12.093-.957-20.793-1.479-20.793-6.699 0-5.568 10.092-7.047 18.357-6.96 9.918.087 18.183 2.175 24.882 7.308l7.656-9.657c-7.743-6.003-19.227-9.309-32.538-9.309-18.792 0-32.364 6.96-32.364 19.662 0 13.05 16.182 16.878 33.582 18.357 14.007 1.218 20.271 2.61 20.271 7.656 0 5.046-6.264 7.395-18.618 7.395-12.44 0-22.098-3.219-29.58-10.092l-8.004 9.483c9.31 7.917 21.837 12.267 37.236 12.267z"/>
      </svg>

  
    )
  }
}

export default SvgPress
