import React from 'react'
//import { Controller, Scene } from 'react-scrollmagic'
import axios from 'axios'
import ScrollMagic from '../lib/scrollmagic'
import Scene from '../components/scene'

// import _ from '../lib/debounce-throttle.js'

import { ParallaxProvider } from 'react-scroll-parallax'

import Layout from '../components/layout'
import SidebarNav from '../components/nav-sidebar'
import SEO from '../components/seo'
import SectionHero from '../components/section-hero'
import SectionAbout from '../components/section-about'
import SectionMenu from '../components/section-menu'
import SectionGallery from '../components/section-gallery'
import SectionLocation from '../components/section-location'
import SectionContact from '../components/section-contact'
import SectionPress from '../components/section-press'
import SectionCatering from '../components/section-catering'

const menu_bottom_buffer = 100

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: {}, image_data: [{}, {}, {}, {}, {}, {}], rotation: 0, menu_scroll_height: 2000 }
    this.controllerElement = null
    this.controller = new ScrollMagic.Controller()
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: `https://gattararestaurant.com/wp-json/acf/v3/options/theme-general-settings/`,
      // url: `http://gattara.local/wp-json/acf/v3/options/theme-general-settings/`,
      config: {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    })
      .then(res => {
        const acf_data = res.data.acf
        this.setState({ data: acf_data })
      })
      .catch(function(error) {
        console.log(error)
      })
    axios({
      method: 'get',
      url: 'https://gattararestaurant.com/wp-json/wp/v2/instagram?per_page=6&_embed',
      config: {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    })
      .then(res => {
        const data = res.data
        this.setState({ image_data: data })
      })
      .catch(function(error) {
        console.log(error)
      })
    this.handleScroll = this.handleScroll.bind(this)
    this.updateBackgroundColor = this.updateBackgroundColor.bind(this)
    //this.throttleScroll = _.throttle(this.handleScroll, 1000)
    //window.addEventListener('scroll', this.throttleScroll)
    window.addEventListener('scroll', this.handleScroll)
    this.updateMenuScrollDuration()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.controllerElement.clientHeight + menu_bottom_buffer !== this.state.menu_scroll_height) {
      this.updateMenuScrollDuration()
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('scroll', this.throttleScroll)
    window.removeEventListener('scroll', this.handleScroll)
  }

  updateMenuScrollDuration() {
    if (this.controllerElement) {
      var height = this.controllerElement.clientHeight + menu_bottom_buffer
      //console.log(height)
      this.setState({ menu_scroll_height: height })
    }
  }

  // Sets the menu indicator position to the middle of the active menu-item
  setIndicatorPosition(event) {
    setTimeout(function() {
      var menu_items = document.getElementsByClassName('menu-item')
      Array.from(menu_items).forEach((element, index, array) => {
        element.classList.remove('is-active')
      })
      var active_els = document.getElementsByClassName('menu-item active')
      var indicator = document.getElementById('indicator')
      if (indicator) {
        if (active_els.length) {
          // something is active, set the top to wherever the middle is
          var active_el = active_els[active_els.length - 1]
          active_el.classList.add('is-active')
          indicator.style.top = active_el.offsetTop + active_el.clientHeight / 2 - indicator.clientHeight / 2 + 'px'
        } else {
          //nothing active, determine if we need to put it at the top or the bottom
          if (event.scrollDirection === 'REVERSE') {
            indicator.style.top = '0px'
          } else if (event.scrollDirection === 'FORWARD') {
            indicator.style.top = '100%'
          } else {
            indicator.style.top = '0px'
          }
        }
      }
    }, 5)
  }
  // Figure out what background color we have, and set the body class
  updateBackgroundColor(event) {
    //let scrollTop = event.target.scrollingElement.scrollTop + window.innerHeight / 2 // middle of window
    let scrollTop = event.target.scrollingElement.scrollTop // top of window
    var sections = document.getElementsByClassName('scene-inner')
    var active_section = null
    for (let section of sections) {
      if (section.offsetTop <= scrollTop) {
        active_section = section
      }
    }
    if (active_section) {
      if (active_section.classList.contains('green-solid')) {
        document.body.classList.add('green-bg')
      } else {
        document.body.classList.remove('green-bg')
      }
      if (active_section.classList.contains('pink-solid')) {
        document.body.classList.add('pink-bg')
      } else {
        document.body.classList.remove('pink-bg')
      }
    } else {
      document.body.classList.remove('green-bg', 'pink-bg')
    }
  }

  handleScroll(event) {
    this.updateBackgroundColor(event)
    var theta = window.scrollY / 1000
    this.setState({ rotation: theta })
  }

  render() {
    const { data, menu_scroll_height, rotation, image_data } = this.state
    return (
      <ParallaxProvider>
        <SEO title="Gattara Denver" description={data.hero_text} />
        <Layout
          address={data.address}
          email={data.email}
          phone={data.phone}
          instagram={data.social_instagram}
          facebook={data.social_facebook}
          reserveLink={data.reserve_table_link}
          orderOnline={data.order_online}
          rotation={rotation}
          data={data}
          pickup_content={data.pick_up_popup}
          delivery_content={data.room_delivery_popup}
        >
          <Scene controller={this.controller} classToggle={'active'} triggerHook={'onLeave'}>
            {(progress, event) => {
              if (event.type === 'enter' || event.type === 'leave') {
                this.setIndicatorPosition(event)
              }
              return (
                <div
                  className={'scene-inner green-solid'}
                  style={{
                    position: 'relative',
                    zIndex: '100',
                  }}>
                  <SectionHero images={data.hero_images} text={data.hero_text} />
                </div>
              )
            }}
          </Scene>

          <Scene
            controller={this.controller}
            triggerHook={'onLeave'}
            pin={true}
            duration={menu_scroll_height}
            pinSettings={{ pushFollowers: false }}
            classToggle={'pinned'}
            indicators={false}>
            <div
              className={'scene-inner menu-scene-wrapper'}
              style={{
                height: 0,
                position: 'relative',
                zIndex: '100',
              }}>
              <SidebarNav />
            </div>
          </Scene>

          <div
            ref={elem => {
              this.controllerElement = elem
            }}>
            <Scene controller={this.controller} classToggle={['.menu-item-about', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner green-solid'}>
                    <SectionAbout
                      about={data.about}
                      about_chef={data.about_chef}
                      about_chef_photo={data.about_chef_photo}
                    />
                  </div>
                )
              }}
            </Scene>

            <Scene controller={this.controller} classToggle={['.menu-item-menus', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner pink-solid'}>
                    <SectionMenu menus={data.menus} rotation={rotation} />
                  </div>
                )
              }}
            </Scene>

            <Scene controller={this.controller} classToggle={['.menu-item-gallery', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner pink-solid'}>
                    <SectionGallery
                      data={image_data}
                      instagram={data.social_instagram}
                      rotation={rotation}
                    />
                  </div>
                )
              }}
            </Scene>

            <Scene controller={this.controller} classToggle={['.menu-item-location', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner green-solid'}>
                    <SectionLocation
                      address={data.address}
                      hours={data.hours}
                      gmaps_url={data.gmaps_url}
                    />
                  </div>
                )
              }}
            </Scene>

            <Scene controller={this.controller} classToggle={['.menu-item-contact', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner green-solid'}>
                    <SectionContact
                      phone={data.phone}
                      email={data.email}
                      instagram={data.social_instagram}
                      facebook={data.social_facebook}
                      rotation={rotation}
                    />
                  </div>
                )
              }}
            </Scene>

            <Scene controller={this.controller} classToggle={['.menu-item-catering', 'active']}>
              {(progress, event) => {
                if (event.type === 'enter' || event.type === 'leave') {
                  this.setIndicatorPosition(event)
                }
                return (
                  <div className={'scene-inner green-solid'}>
                    <SectionCatering catering={data.catering} />
                  </div>
                )
              }}
            </Scene>
          </div>

          <Scene controller={this.controller} classToggle={['.menu-item-press', 'active']}>
            {(progress, event) => {
              if (event.type === 'enter' || event.type === 'leave') {
                this.setIndicatorPosition(event)
              }
              return (
                <div className={'scene-inner green-solid'}>
                  <SectionPress press={data.press} pressContact={data.press_contact_details} video_embed_code={data.video_embed_code} video_text={data.video_text} />
                </div>
              )
            }}
          </Scene>
          
        </Layout>
      </ParallaxProvider>
    )
  }
}
